<template>
  <div class="progress-component">
    <div id="bar-container">
    </div>
    <div class="percentage">
      <span>
        {{percentage}}
      </span>
    </div>
  </div>
</template>

<script>
import ProgressBar from 'progressbar.js';

export default {
  name: 'Progress',
  props: {
    value: Number,
  },
  data() {
    return {
      bar: undefined,
    };
  },
  mounted() {
    const color = '#198754';

    const strokeWidth = 14;
    if (!this.bar) {
      this.bar = new ProgressBar.Circle('#bar-container', {
        color,
        strokeWidth,
        trailWidth: strokeWidth,
        easing: 'easeOut',
        duration: 500,
        from: { color, width: strokeWidth },
        to: { color, width: strokeWidth },
      });
    }
    this.bar.animate(this.value);
  },
  beforeUnmount() {
    if (this.bar) {
      this.bar.destroy();
      this.bar = undefined;
      document.getElementById('bar-container').innerHTML = '';
    }
  },
  watch: {
    value() {
      this.bar.animate(this.value);
    },
  },
  computed: {
    percentage() {
      const result = `${parseInt(this.value * 100, 10)}%`;

      if (this.value < 1.0 && result === '100%') return '99%';

      return result;
    },
  },
};
</script>

<style scoped>
#bar-container {
  position: relative;
  width: 1.2em;
  height: 1.2em;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2.2em;
  /*margin-left: -8em;*/
}

.percentage {
  font-size: 0.5em;
  /*font-weight: bold;*/
  color: #999;
  width: 3em;
  display: block;
  margin-left: 0.1em;
  text-align: center;
  position: relative;

}
.percentage span {
  position: absolute;
  top: -1.95em;
  left: 0em;
  width: 3.4em;
  /*background-color: red;*/
}

@media (min-width: 992px) {
  #bar-container {
    width: 1.1em;
    height: 1.1em;
    margin-top: -0.1em;
    /*margin-left: -8.8em;*/
  }

  .percentage {
    display: inline-block;
    font-size: 0.5em;
    text-align: right;
    width: 3em;
    margin-left: 0.1em;
  }
  .percentage span {
    text-align: left;
    left: 0.4em;
    top: -1.3em;
  }
}
</style>
