const credentials = {
  firebase: {
    apiKey: 'AIzaSyAaG4kRsS-zbrPa0G5hNTTU1wNIUszP-7w',
    authDomain: 'garni-73e13.firebaseapp.com',
    projectId: 'garni-73e13',
    storageBucket: 'garni-73e13.appspot.com',
    messagingSenderId: '418345451549',
    appId: '1:418345451549:web:b87e1d7b7ba255321946f4',
  },
};

export default credentials;
