<template>
  <div class="index">

    <div v-if="bookmarks.length > 0">
      <h1>Hoje</h1>

      <div class="box base-box">
        <ul>
          <li v-for="(recipe, i) in bookmarks" :key="`f-${recipe.name}-${i}`">
            <a :href="`#${recipe.path}`" @click="goTo(recipe.path, $event)">
              {{recipe.name}}
            </a>
          </li>
        </ul>
      </div>

      <div class="bookmarks-actions">
        <button
          @click="clearBookmarks"
          type="button" class="btn btn-danger btn-sm">limpar</button>
      </div>
    </div>

    <div v-if="remaining.length > 0">
      <h1>Todos os Pratos</h1>

      <div class="box base-box">
        <ul>
          <li v-for="(recipe, i) in remaining" :key="`r-${recipe.name}-${i}`">
            <a :href="`#${recipe.path}`" @click="goTo(recipe.path, $event)">
              {{recipe.name}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';

import { inject } from 'vue';

import BookmarkModel from '../models/bookmark';

export default {
  name: 'Index',
  props: {
    index: Array,
  },
  data() {
    return {
      bookmarkIds: [],
    };
  },
  async created() {
    this.storage = inject('storage');
    this.goTo = inject('goTo');
    this.registerOnStorage = inject('registerOnStorage');
    this.confirmModal = inject('confirmModal');

    this.bookmarkIds = await BookmarkModel.load(this.storage);

    this.registerOnStorage('index', this.storageUpdated);
  },
  methods: {
    async storageUpdated() {
      this.bookmarkIds = await BookmarkModel.load(this.storage);
    },
    async clearBookmarks() {
      await this.confirmModal().open(
        'Deseja remove todas as receitas favoritas?',
        async () => {
          this.bookmarkIds = await BookmarkModel.clear(this.storage);
        },
      );
    },
  },
  computed: {
    sortedIndex() {
      return _.sortBy(this.index, ['name']);
    },
    bookmarks() {
      return _.filter(this.sortedIndex, (r) => this.bookmarkIds.includes(r.id));
    },
    remaining() {
      return _.filter(this.sortedIndex, (r) => !this.bookmarkIds.includes(r.id));
    },
  },
};
</script>
