<template>
  <div :class="cssClass" v-if="ready">
    {{ presentedMeasure.amount }} {{ presentedMeasure.label }}

    <a
      v-if="this.factoredMeasures.length > 1"
      href="javascript:void(0);"
      @click="nextMeasure">unidade</a>

    <a v-else href="javascript:void(0);" class="single-unit" @click="nextMeasure">unidade</a>
  </div>
</template>

<script>
import _ from 'lodash';

import Measures from '../alchimia/measures';
import Presenter from '../alchimia/presenter';

export default {
  name: 'TinyIngredient',
  props: {
    dishId: String,
    display: Object,
    done: Boolean,
    highPrecision: Boolean,
    ingredient: Object,
    sharedIngredients: Array,
    units: Object,
    itemId: Number,
  },
  inject: ['updateSumFor', 'storage'],
  data() {
    return {
      ready: false,
      currentIndex: 0,
      factor: 1.0,
      factoredMeasures: [],
      allowedUnits: [],
    };
  },
  async created() {
    this.buildAllowedUnits();

    await this.loadFactor();
    this.updateMeasures();
    await this.loadUnit();

    this.ready = true;
  },
  methods: {
    buildAllowedUnits() {
      this.sharedIngredients.forEach((ingredient) => {
        this.allowedUnits = [...this.allowedUnits, ...Presenter.removeUnhelpful(
          Measures.buildFor(
            this.units, ingredient.amount, ingredient.unit,
          ),
        ).map((m) => m.unit)];
      });

      this.allowedUnits = _.uniq(this.allowedUnits);
    },
    async loadFactor() {
      const ingredientFactor = await this.storage.getItem(
        `dish:${this.dishId}:${this.ingredient.id}/fx`,
      );

      if (ingredientFactor !== null) {
        this.factor = parseFloat(ingredientFactor);
        return;
      }

      const dishFactor = await this.storage.getItem(`dish:${this.dishId}/fx`);

      if (dishFactor !== null) {
        this.factor = parseFloat(dishFactor);
        return;
      }

      this.factor = 1.0;
    },
    async nextMeasure(event) {
      event.stopPropagation();

      if (this.currentIndex < this.factoredMeasures.length - 1) {
        this.currentIndex += 1;
      } else {
        this.currentIndex = 0;
      }

      await this.saveUnit();
    },
    async saveUnit() {
      await this.storage.setItem(this.unitKey, this.currentFactoredMeasure.unit);
      await this.updateSumFor(this.itemId);
    },
    async loadUnit() {
      if (this.setUnit(`${await this.storage.getItem(this.unitKey)}`)) return;

      if (this.ingredient['display-unit']) {
        this.setUnit(this.ingredient['display-unit']);
      } else {
        this.setUnit(this.ingredient.unit);
      }
    },
    setUnit(target) {
      let unit = target;

      if (target.includes('/')) [, unit] = target.split('/');

      const index = _.findIndex(this.factoredMeasures, (m) => m.unit === unit);

      if (index >= 0) {
        this.currentIndex = index;
        return true;
      }
      return false;
    },
    updateMeasures() {
      const factoredAmount = this.ingredient.amount * this.factor;

      this.factoredMeasures = Measures.buildFor(
        this.units, factoredAmount, this.ingredient.unit,
      ).filter((m) => this.allowedUnits.includes(m.unit));
    },
  },
  computed: {
    cssClass() {
      if (!this.done) return 'tiny-ingredient';

      return 'tiny-ingredient done';
    },
    unitKey() {
      return `ingredient:${this.dishId}:${this.ingredient.id}/u`;
    },
    currentFactoredMeasure() {
      return this.factoredMeasures[this.currentIndex];
    },
    presentedMeasure() {
      return Presenter.measure(
        this.display,
        this.currentFactoredMeasure.amount,
        this.currentFactoredMeasure.unit,
        this.highPrecision,
        'up',
      );
    },
  },
};
</script>
