<template>
  <div class="todo">
    <div class="items" id="items">
      <div class="item" :data-id="item.id" v-for="item in items" :key="`item-${item.id}-${order}`">
        <div class="row">
          <div class="col-2 col-md-1 draggable check-area">
            <div class="form-check">
              <input
                @click="toggle(item.id, $event)"
                :checked="item.done"
                class="form-check-input"
                type="checkbox">
            </div>
          </div>
          <div class="col title" @click="toggle(item.id, $event)">
            <div v-if="isEditing(item.id)">
              <input
                :id="`item-${item.id}`" type="text"
                @keyup.enter="save"
                v-model="currentItem.title"
                class="form-control title-input item-title"
                placeholder="título" aria-label="título">
            </div>
            <div class="item-title" v-else>
              <div v-if="item.done" class="done">{{ item.title }}</div>
              <div v-else>{{ item.title }}</div>
            </div>
          </div>
          <div class="col-5 col-lg-3 item-actions">
            <div class="btn-group">
              <button
                @click="nextMeasureFor(item.id, $event)"
                v-if="measureDisplay(item.id) === 'yes'"
                type="button"
                class="btn btn-warning btn-measure">
                  <i class="bi bi-arrow-counterclockwise"></i>
                </button>

              <button
                v-if="measureDisplay(item.id) === 'disabled'"
                type="button"
                disabled
                class="btn btn-secondary btn-measure">
                  <i class="bi bi-arrow-counterclockwise"></i>
                </button>
            </div>

            <div class="btn-group" v-if="isEditing(item.id)">
              <button @click="reset" type="button" class="btn btn-outline-danger">
                <i class="bi bi-x-circle"></i>
              </button>
              <button @click="save" type="button" class="btn btn-success">
                <i class="bi bi-check-circle-fill"></i>
              </button>
            </div>
            <div class="btn-group" v-else>
              <button @click="edit(item.id)" type="button" class="btn btn-primary">
                <i class="bi bi-pencil"></i>
              </button>
              <button @click="remove(item.id)" type="button" class="btn btn-danger">
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <div
          class="row tiny tiny-dish-row"
          v-if="item.sharedIngredients.length > 0"
          @click="toggle(item.id, $event)">
          <div class="draggable col-2 col-md-1 check-area">
          </div>
          <div class="col tiny-dish-container">

            <transition name="fade-fast">
              <div class="tiny-loading-container" v-if="itemLoading(item.id)">
                <TinyLoading/>
              </div>
            </transition>
            <transition name="fade-fast">
              <TinyConsolidated
                :ref="`tc-${item.id}`"
                :done="item.done"
                :item-id="item.id"
                :display="display"
                :item-title="item.title"
                :dish-ids="item.dishIds"
                :units="units"
                :shared-ingredients="item.sharedIngredients"
                :high-precision="highPrecision" />
            </transition>
            <div class="tiny-debug">
              <TinyDish
                v-for="dishId in item.dishIds"

                :key="`td-${item.id}-${dishId}-${item.ingredientSharedId}`"
                :ref="`td-${item.id}-${dishId}-${item.ingredientSharedId}`"
                :sample-ingredient="sharedIngredientsFor(dishId, item)[0]"
                :done="item.done"
                :item-id="item.id"
                :item-title="item.title"
                :units="units"
                :display="display"
                :shared-ingredients="sharedIngredientsFor(dishId, item)"
                :all-shared-ingredients="item.sharedIngredients"
                :high-precision="highPrecision"
                :dish-id="dishId"
                :dish-path="item.dishPath">

              <TinyIngredient
                v-for="ingredient in sharedIngredientsFor(dishId, item)"
                :ref="`ti-${item.id}-${dishId}-${ingredient.id}`"
                :key="`ti-${item.id}-${dishId}-${ingredient.id}`"
                :ingredient="ingredient"
                :done="item.done"
                :units="units"
                :display="display"
                :item-id="item.id"
                :shared-ingredients="sharedIngredientsFor(dishId, item)"
                :high-precision="highPrecision"
                :dish-id="ingredient.dishId" />

              </TinyDish>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="item new-item" v-if="creating">
      <div class="row">
        <div class="col">
          <input
            id="new-item" type="text"
            @keyup.enter="save"
            v-model="currentItem.title"
            class="form-control"
            placeholder="novo item" aria-label="novo item">
        </div>
        <div class="col-5 col-lg-2 item-actions">
          <button @click="save" type="button" class="btn btn-success">
            <i class="bi bi-check-circle-fill"></i>
          </button>
          <button @click="reset" type="button" class="btn btn-outline-danger">
            <i class="bi bi-x-circle"></i>
          </button>
        </div>
      </div>
    </div>

    <div v-if="empty && !creating" class="empty">
      Aqui você pode criar a sua lista com os itens necessários para a sua receita.
      <br><br>
      Crie seus itens manualmente ou vá para alguma receita
      e adicione os ingredientes que você precisa adquirir.
    </div>

    <div class="actions" v-if="!creating">
      <button @click="clear" v-if="items.length > 1" type="button" class="btn btn-danger">
        <i class="bi bi-trash-fill"></i> limpar
      </button>
      <button @click="create" type="button" class="btn btn-success">
        <i class="bi bi-plus-circle-fill"></i> novo
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import { inject, provide } from 'vue';

import Sortable from 'sortablejs';
import TinyDish from './TinyDish.vue';
import TinyLoading from './TinyLoading.vue';
import TinyIngredient from './TinyIngredient.vue';
import TinyConsolidated from './TinyConsolidated.vue';

import ToDoModel from '../models/todo';

export default {
  name: 'ToDo',
  components: {
    TinyDish, TinyIngredient, TinyConsolidated, TinyLoading,
  },
  props: {
    units: Object,
    display: Object,
    highPrecision: Boolean,
    id: String,
  },
  data() {
    return {
      order: 'initial',
      pendingDishes: {},
      empty: true,
      creating: false,
      editing: null,
      currentItem: { id: undefined, title: '', done: false },
      items: [],
      displayMeasure: {},
    };
  },
  async created() {
    provide('updateItemUnits', this.updateItemUnits);
    provide('updateSumFor', this.updateSumFor);
    provide('setDisplayMeasure', this.setDisplayMeasure);
    provide('updateConsolidatedFor', this.updateConsolidatedFor);
    provide('updateConsolidatedFor', this.updateConsolidatedFor);
    provide('setDishFor', this.setDishFor);
    provide('dishLoading', this.dishLoading);
    provide('dishLoaded', this.dishLoaded);

    this.storage = inject('storage');
    this.pageLoaded = inject('pageLoaded');
    this.updateGrocery = inject('updateGrocery');
    this.registerOnStorage = inject('registerOnStorage');
    this.confirmModal = inject('confirmModal');

    window.addEventListener('keydown', this.onKeydown);

    const list = await ToDoModel.loadList(this.storage, this.id);
    this.items = list.items;

    this.empty = this.items.length === 0;

    this.registerOnStorage('todo', this.storageUpdated);
  },
  mounted() {
    this.pageLoaded();

    Sortable.create(document.getElementById('items'), {
      handle: '.draggable',
      animation: 150,
      ghostClass: 'dragging',
      onEnd: this.onDragEnd,
    });
  },
  methods: {
    itemLoading(itemId) {
      return (
        this.pendingDishes[itemId] !== undefined
        && this.pendingDishes[itemId] > 0
      );
    },
    dishLoading(itemId) {
      if (this.pendingDishes[itemId] === undefined) {
        this.pendingDishes[itemId] = 0;
      }
      this.pendingDishes[itemId] += 1;
    },
    dishLoaded(itemId) {
      if (this.pendingDishes[itemId] === undefined) {
        this.pendingDishes[itemId] = 0;
      }
      this.pendingDishes[itemId] -= 1;
    },
    setDishFor(itemId, dish) {
      this.$refs[`tc-${itemId}`].setDish(dish);
    },
    sharedIngredientsFor(dishId, item) {
      return _.filter(item.sharedIngredients, (si) => si.dishId === dishId);
    },
    setDisplayMeasure(itemId, value) {
      this.displayMeasure[itemId] = value;
    },
    measureDisplay(itemId) {
      if (this.displayMeasure[itemId] === undefined) {
        return 'no';
      } if (this.displayMeasure[itemId] > 1) {
        return 'yes';
      }
      return 'disabled';
    },
    async nextMeasureFor(itemId, event) {
      const item = _.find(this.items, (i) => i.id === itemId);

      await this.$refs[`tc-${item.id}`].nextMeasure(event);
    },
    updateConsolidatedFor(itemId) {
      const item = _.find(this.items, (i) => i.id === itemId);

      const refs = [];

      item.dishIds.forEach((dishId) => {
        refs.push(
          this.$refs[
            `td-${item.id}-${dishId}-${item.ingredientSharedId}`
          ],
        );
      });

      if (item.dishIds.length > 0) {
        this.$refs[`tc-${itemId}`].build(refs);
      }
    },
    async updateSumFor(itemId) {
      const item = _.find(this.items, (i) => i.id === itemId);

      for (let i = 0; i < item.dishIds.length; i += 1) {
        const dishId = item.dishIds[i];

        /* eslint-disable no-await-in-loop */
        await this.$refs[
          `td-${item.id}-${dishId}-${item.ingredientSharedId}`
        ].updateSum();
        /* eslint-enable no-await-in-loop */
      }

      this.updateConsolidatedFor(item.id);
    },
    async updateItemUnits(itemId) {
      const item = _.find(this.items, (i) => i.id === itemId);

      for (let i = 0; i < item.sharedIngredients.length; i += 1) {
        const ingredient = item.sharedIngredients[i];

        /* eslint-disable no-await-in-loop */
        await this.$refs[`ti-${item.id}-${ingredient.dishId}-${ingredient.id}`].loadUnit();
        /* eslint-enable no-await-in-loop */
      }
    },
    async onDragEnd() {
      const itens = document.getElementsByClassName('item');

      const ids = [];

      for (let i = 0; i < itens.length; i += 1) {
        ids.push(parseInt(itens[i].dataset.id, 10));
      }

      const list = await ToDoModel.saveOrder(this.storage, this.id, ids);
      this.items = list.items;
      this.order = performance.now();
    },
    async storageUpdated() {
      const list = await ToDoModel.loadList(this.storage, this.id);
      this.items = list.items;

      this.empty = this.items.length === 0;

      setTimeout(() => {
        this.items.forEach((item) => this.updateConsolidatedFor(item.id));
      }, 0);

      for (let it = 0; it < this.items.length; it += 1) {
        const item = this.items[it];

        if (item.sharedIngredients) {
          for (let si = 0; si < item.sharedIngredients.length; si += 1) {
            const ingredient = item.dishIds[si];

            if (this.$refs[`ti-${item.id}-${ingredient.dishId}-${ingredient.id}`]) {
              /* eslint-disable no-await-in-loop */
              await this.$refs[`ti-${item.id}-${ingredient.dishId}-${ingredient.id}`].loadFactor();
              /* eslint-enable no-await-in-loop */
              this.$refs[`ti-${item.id}-${ingredient.dishId}-${ingredient.id}`].updateMeasures();
            }
          }
          if (item.sharedIngredients[0]) {
            for (let di = 0; di < item.dishIds.length; di += 1) {
              const dishId = item.dishIds[di];

              const ref = this.$refs[
                `td-${item.id}-${dishId}-${item.ingredientSharedId}`
              ];

              if (ref) {
                /* eslint-disable no-await-in-loop */
                await ref.loadFactor();
                await ref.updateSum();
                /* eslint-enable no-await-in-loop */
              }
            }
          }
        }
      }

      for (let it = 0; it < this.items.length; it += 1) {
        const item = this.items[it];

        if (item.sharedIngredients) {
          for (let ig = 0; ig < item.sharedIngredients.length; ig += 1) {
            const ingredient = item.sharedIngredients[ig];

            if (this.$refs[`ti-${item.id}-${ingredient.dishId}-${ingredient.id}`]) {
              /* eslint-disable no-await-in-loop */
              await this.$refs[`ti-${item.id}-${ingredient.dishId}-${ingredient.id}`].loadUnit();
              /* eslint-enable no-await-in-loop */
            }
          }

          if (item.sharedIngredients[0]) {
            for (let di = 0; di < item.dishIds.length; di += 1) {
              const dishId = item.dishIds[di];
              const ref = this.$refs[
                `td-${item.id}-${dishId}-${item.ingredientSharedId}`
              ];

              if (ref) {
                /* eslint-disable no-await-in-loop */
                await ref.loadUnit();
                await ref.updateSum();
                /* eslint-enable no-await-in-loop */
              }
            }
          }
        }
      }
    },
    async clear() {
      await this.confirmModal().open(
        'Deseja realmente apagar todos os itens?',
        async () => {
          const list = await ToDoModel.removeAll(this.storage, this.id);
          this.items = list.items;

          this.empty = this.items.length === 0;
          await this.updateGrocery();
          this.reset();
        },
      );
    },
    async toggle(id, event) {
      if (
        event.target.tagName === 'INPUT'
        && event.target.getAttribute('type') === 'text'
      ) return;

      const list = await ToDoModel.toggle(this.storage, this.id, id);
      this.items = list.items;
      await this.updateGrocery();
    },
    isEditing(id) {
      return this.editing === id;
    },
    edit(id) {
      this.reset();
      this.editing = id;
      this.currentItem = { ..._.find(this.items, (i) => i.id === id) };
      setTimeout(() => { document.getElementById(`item-${id}`).focus(); }, 0);
    },
    async remove(id) {
      await this.confirmModal().open(
        'Deseja realmente apagar?',
        async () => {
          const list = await ToDoModel.remove(this.storage, this.id, id);
          this.items = list.items;

          this.empty = this.items.length === 0;

          await this.updateGrocery();
          this.reset();
        },
      );
    },
    create() {
      this.reset();
      this.creating = true;
      setTimeout(() => { document.getElementById('new-item').focus(); }, 0);
    },
    async save() {
      let list;
      if (this.editing) {
        list = await ToDoModel.updateTitle(
          this.storage,
          this.id, this.editing, this.currentItem.title,
        );
      } else {
        list = await ToDoModel.add(this.storage, this.id, this.currentItem.title);
      }

      this.items = list.items;

      this.empty = this.items.length === 0;
      this.reset();
      await this.updateGrocery();
    },
    reset() {
      this.creating = false;
      this.editing = null;
      this.resetItem();
    },
    resetItem() {
      this.currentItem.id = undefined;
      this.currentItem.title = '';
      this.currentItem.done = false;
    },
    onKeydown(e) {
      if (['Escape'].includes(e.key)) {
        this.reset();
      }
    },
  },
};
</script>
