<template>
  <div class="sandbox">
    <h1>Sandbox</h1>
    <div class="base-box">

      <Ingredient
          v-for="ingredient in ingredients"
          :key="ingredient.id"
          :highPrecision="highPrecision"
          :units="units"
          :recipe-index="0"
          :step-index="0"
          :dish-id="dishId"
          :dish-path="dishPath"
          :display="display"
          microMoment="explore"
          :factor="1.0"
          :locked="false"
          :ingredient="ingredient" />

      <SystemDoneness :path="systemSettings.import" :settings="systemSettings" />
    </div>
  </div>
</template>

<script>
import { provide } from 'vue';

import Ingredient from '../components/Ingredient.vue';
import SystemDoneness from '../components/systems/Doneness.vue';

export default {
  name: 'Sandbox',
  components: { Ingredient, SystemDoneness },
  inject: ['pageLoaded'],
  props: {
    units: Object,
    display: Object,
    highPrecision: Boolean,
  },
  data() {
    return {
      systemSettings: {
        import: 'doneness/beef',
        preheat: {
          description: 'Frigideira no Fogo Alto',
          minutes: 5,
        },
        cooking: 'Diminuir para Fogo Médio',
        minutes: {
          'mal-passada': 1.5,
          'ponto-mal': 2,
          'ao-ponto': 2.5,
          ponto: 2.5,
          'ponto-bem': 3.5,
          'bem-passada': 4.5,
        },
      },

      system: undefined,
      dishId: 'sandbox',
      dishPath: 'sandbox',
      ingredients: [
        {
          name: 'Fraldinha',
          amount: 180,
          unit: 'g',
          id: '1-5b2598cfdf7485b5c31ca039267c467a',
          'shared-id': '5b2598cfdf7485b5c31ca039267c467a',
        },
        {
          name: 'Sal Marinho',
          amount: 1,
          unit: 'sal/pitada',
          id: '3-1fca5a56e8f33fc345c1c3492c95312b',
          'shared-id': '1fca5a56e8f33fc345c1c3492c95312b',
        },
      ],
    };
  },
  created() {
    [
      'closeAllToolbars', 'updateDishFactor',
      'ingredientChecked', 'toggleIngredientCheck',
      'updateIngredientsGrocery', 'updateIngredientsUnit',
      'lockStepFactorAs',
    ].forEach((key) => {
      provide(key, () => {});
    });

    provide(
      'sharedIngredients',
      (sharedId) => this.ingredients.filter((i) => i['shared-id'] === sharedId),
    );
  },
};
</script>

<style scoped>
</style>
