import _ from 'lodash';

class BookmarkModel {
  static async add(storage, dishId) {
    const ids = await this.load(storage);

    const index = _.findIndex(ids, (id) => id === dishId);

    if (index > -1) return ids;

    ids.push(dishId);

    return this.save(storage, ids);
  }

  static async remove(storage, dishId) {
    const ids = await this.load(storage);

    const index = _.findIndex(ids, (id) => id === dishId);

    if (index < 0) return ids;

    ids.splice(index, 1);

    return this.save(storage, ids);
  }

  static async clear(storage) {
    return this.save(storage, []);
  }

  static async save(storage, ids) {
    const storageKey = 'bookmarks';

    await storage.setItem(storageKey, ids);

    return ids;
  }

  static async load(storage) {
    const storageKey = 'bookmarks';

    let ids = await storage.getItem(storageKey);

    if (ids === null) {
      ids = [];
    }

    return ids;
  }

  static async is(storage, dishId) {
    const ids = await this.load(storage);

    return _.findIndex(ids, (id) => id === dishId) > -1;
  }
}

export default BookmarkModel;
