<template>
  <div :class="cssClass">
    <div class="amounts">
    <div v-for="ingredient in addedIngredients" :key="`tc-i-${itemId}-${ingredient.id}`">
      {{ingredient.amount}} {{ingredient.label}}
    </div>
    </div>
    <div class="ingredient-name" v-if="itemTitle !== sampleIngredient.name">
      {{ sampleIngredient.name }}
    </div>
    <div class="dishes-links" v-if="dishIds.length > 0">
      <div v-for="dishId in dishIds" :key="`tc-d-${itemId}-${dishId}`">
        <a v-if="dishes[dishId]" class="dish-name link-primary"
          :href="`#${dishes[dishId].path}`" @click="goTo(dishes[dishId].path, $event)">
          {{ dishes[dishId].name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import Measures from '../alchimia/measures';
import Presenter from '../alchimia/presenter';

export default {
  name: 'TinyConsolidated',
  props: {
    itemId: Number,
    done: Boolean,
    itemTitle: String,
    display: Object,
    highPrecision: Boolean,
    sharedIngredients: Array,
    units: Object,
    dishIds: Array,
  },
  inject: ['setDisplayMeasure', 'goTo'],
  data() {
    return {
      addedIngredients: [],
      currentIndex: 0,
      allowedUnits: [],
      tinyDishes: [],
      dishes: {},
    };
  },
  created() {
    this.buildAllowedUnits();
  },
  methods: {
    buildAllowedUnits() {
      this.sharedIngredients.forEach((ingredient) => {
        this.allowedUnits = [...this.allowedUnits, ...Presenter.removeUnhelpful(
          Measures.buildFor(
            this.units, ingredient.amount, ingredient.unit,
          ),
        ).map((m) => m.unit)];
      });

      this.allowedUnits = _.uniq(this.allowedUnits);

      this.setDisplayMeasure(this.itemId, this.allowedUnits.length);
    },
    setDish(dish) {
      this.dishes[dish.id] = dish;
    },
    build(tinyDishes) {
      this.tinyDishes = tinyDishes;
      const ingredients = {};

      this.tinyDishes.forEach((tinyDish) => {
        tinyDish.addedIngredients.forEach((ingredient) => {
          if (!ingredients[ingredient.unit]) {
            ingredients[ingredient.unit] = {
              count: 0,
              amount: 0,
              unit: ingredient.unit,
            };
          }

          ingredients[ingredient.unit].amount += ingredient.rawAmount;
          ingredients[ingredient.unit].count += 1;
        });
      });

      this.addedIngredients = Object.values(ingredients).map((i) => ({
        ...{ rawAmount: i.amount },
        ...Presenter.measure(
          this.display, i.amount, i.unit,
          this.highPrecision, 'up',
        ),
      }));

      if (this.addedIngredients.length === 1) {
        this.setUnit(this.addedIngredients[0].unit);
      } else if (this.addedIngredients.length > 1) {
        const ingredient = _.last(_.sortBy(Object.values(ingredients), ['count']));
        this.setPreviousTo(ingredient.unit);
      }
    },
    async nextMeasure(event) {
      event.stopPropagation();

      if (this.currentIndex < this.allowedUnits.length - 1) {
        this.currentIndex += 1;
      } else {
        this.currentIndex = 0;
      }

      for (let i = 0; i < this.tinyDishes.length; i += 1) {
        const tinyDish = this.tinyDishes[i];

        tinyDish.setUnit(this.currentUnit);
      }

      for (let i = 0; i < this.tinyDishes.length; i += 1) {
        const tinyDish = this.tinyDishes[i];

        /* eslint-disable no-await-in-loop */
        await tinyDish.saveUnit();
        await tinyDish.updateSum();
        /* eslint-enable no-await-in-loop */
      }
    },
    setUnit(target, dontSetIndex) {
      if (!target) return false;

      let unit = target;

      if (target.includes('/')) [, unit] = target.split('/');

      const index = _.findIndex(this.allowedUnits, ((au) => au === unit));

      if (index >= 0) {
        if (!dontSetIndex) {
          this.currentIndex = index;
        }
        return true;
      }
      return false;
    },
    setPreviousTo(target) {
      let unit = target;

      if (target.includes('/')) [, unit] = target.split('/');

      const index = _.findIndex(this.allowedUnits, ((au) => au === unit));

      if (index >= 0) {
        if (index > 1) {
          this.currentIndex = index - 1;
        } else {
          this.currentIndex = this.allowedUnits.length - 1;
        }
      }
    },
  },
  computed: {
    sampleIngredient() {
      return this.sharedIngredients[0];
    },
    currentUnit() {
      return this.allowedUnits[this.currentIndex];
    },
    cssClass() {
      if (!this.done) return 'tiny-dish';

      return 'tiny-dish done';
    },
  },
};
</script>
