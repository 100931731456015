import _ from 'lodash';

import Units from './units';
import Conversions from './conversions';
import Density from './density';

class Measures {
  static buildFor(baseUnits, baseAmount, baseKey, unsorted/* , debug */) {
    const units = { ...baseUnits };

    let amount = baseAmount;
    let key = baseKey;

    let [baseIngredient, baseUnit] = key.split('/');

    if (baseUnit === undefined) {
      baseIngredient = undefined;
      baseUnit = key;
    }

    if (units.ml) {
      const mlKeys = Object.keys(units.ml);

      if (mlKeys.includes(baseUnit)) {
        amount *= units.ml[baseUnit];
        baseUnit = 'ml';
        if (units[`${baseIngredient}/${baseUnit}`]) {
          key = `${baseIngredient}/${baseUnit}`;
        } else {
          key = 'ml';
        }
      }
    }

    if (units.g) {
      const gKeys = Object.keys(units.g);

      if (gKeys.includes(baseUnit)) {
        amount *= units.g[baseUnit];
        baseUnit = 'g';
        if (units[`${baseIngredient}/${baseUnit}`]) {
          key = `${baseIngredient}/${baseUnit}`;
        } else {
          key = 'g';
        }
      }
    }

    // if (debug) console.log('baseUnit', baseUnit);

    if (units[key] === null || units[key] === undefined) {
      units[key] = {};

      if (baseUnit !== undefined && units.ml[baseUnit]) {
        const newKey = `${baseIngredient}/ml`;
        if (units[newKey] !== undefined) {
          key = newKey;
        } else {
          key = 'ml';
        }
        amount *= units.ml[baseUnit];
      } else if (baseUnit !== undefined && units.g[baseUnit]) {
        const newKey = `${baseIngredient}/g`;
        if (units[newKey] !== undefined) {
          key = newKey;
        } else {
          key = 'g';
        }
        amount *= units.g[baseUnit];
      }
    }

    const conversions = Conversions.buildFor(units, key);

    const density = Density.calculateFor(units, key);

    const measures = [];

    const mls = [];
    const gs = [];

    const measuresUnits = {};

    if (conversions.from === 'ml') mls.push(amount);
    if (conversions.from === 'g') gs.push(amount);

    measuresUnits[conversions.from] = true;

    if (conversions.from !== 'ml' && conversions.from !== 'g') {
      measures.push({
        amount,
        unit: conversions.from,
      });
      // if (debug) console.log('1 >>>>>', amount, conversions.from);
      measuresUnits[conversions.from] = true;
    }

    if (!Units.STANDARDS.includes(conversions.from)) {
      Object.keys(units[key]).forEach((target) => {
        if (Units.STANDARDS.includes(target)) {
          if (target !== 'ml' && target !== 'g') {
            measures.push({
              amount: amount * units[key][target],
              unit: target,
            });

            // if (debug) console.log('2 >>>>>', target, amount * units[key][target]);
          }

          if (target === 'ml') mls.push(amount * units[key][target]);
          if (target === 'g') gs.push(amount * units[key][target]);

          measuresUnits[target] = true;
        } else if (units.ml[target] !== undefined || units.g[target] !== undefined) {
          if (units.ml[target] !== undefined) {
            measures.push({
              amount: amount * units[key][target],
              unit: target,
            });

            // if (debug) console.log('3 >>>>>', target, amount * units[key][target]);

            measuresUnits[target] = true;

            mls.push(amount * units[key][target] * units.ml[target]);
          }

          if (units.g[target] !== undefined) {
            measures.push({
              amount: amount * units[key][target],
              unit: target,
            });

            // if (debug) console.log('3 >>>>>', target, amount * units[key][target]);

            measuresUnits[target] = true;

            gs.push(amount * units[key][target] * units.g[target]);
          }
        } else {
          measures.push({
            amount: amount * units[key][target],
            unit: target,
          });

          // if (debug) console.log('4 >>>>>', target, amount * units[key][target]);

          measuresUnits[target] = true;
        }
      });
    } else {
      Object.keys(units[key]).forEach((target) => {
        if (
          !Units.NO_FRACTIONS.includes(target)
          && !units.ml[target]
          && !units.g[target]
          && target !== 'density'
        ) {
          measures.push({
            amount: amount * units[key][target],
            unit: target,
          });
          // if (debug) console.log('5 >>>>>', baseUnit, target, amount * units[key][target]);
          measuresUnits[target] = true;
        }
      });
    }

    const gKeys = ['g', ...Object.keys(units.g)];
    const mlKeys = ['ml', ...Object.keys(units.ml)];

    if (density !== undefined) {
      conversions.to.forEach((target) => {
        // console.log('> ', conversions.from, '->', target);
        if (
          !(gKeys.includes(target) && gKeys.includes(conversions.from))
          && !(mlKeys.includes(target) && mlKeys.includes(conversions.from))
        ) {
          const targetAmount = amount * density;

          if (target === 'ml') {
            mls.push(targetAmount);
            measuresUnits[target] = true;
          }

          if (target === 'g') {
            gs.push(targetAmount);
            measuresUnits[target] = true;
          }

          // console.log('!!!!!!!!!', target, targetAmount);

          if (target !== 'ml' && target !== 'g' && Units.NO_FRACTIONS.includes(target)) {
            measures.push({
              amount: targetAmount / units.g[target],
              unit: target,
            });
            // if (debug) console.log('6 >>>>>', target, targetAmount);
            measuresUnits[target] = true;
          }
        }
      });
      // console.log('_______________________');
    }

    let ml;
    let g;

    // if (debug) console.log('>> mls', mls);
    // if (debug) console.log('>> gs', gs);

    if (mls.length > 0) {
      const mlsCheck = mls.map((d) => (d / 10).toFixed(0));

      if (_.uniq(mlsCheck).length > 1) {
        throw new Error(`Incoherent mls for "${key}" [${mlsCheck.join(' ')}]`);
      }

      ml = _.mean(mls);
    }

    if (gs.length > 0) {
      const gsCheck = gs.map((d) => (d / 10).toFixed(0));

      if (_.uniq(gsCheck).length > 1) {
        throw new Error(`Incoherent gs for "${key}" [${gsCheck.join(' ')}]`);
      }

      g = _.mean(gs);
    }

    if (ml !== undefined) {
      Object.keys(units.ml).forEach((target) => {
        if (!measuresUnits[target]) {
          measures.push({
            amount: ml / units.ml[target],
            unit: target,
          });
          // if (debug) console.log('7 >>>>>', target, ml / units.ml[target]);
          measuresUnits[target] = true;
        }
      });

      measures.push({
        amount: ml,
        unit: 'ml',
      });
    }

    if (g !== undefined) {
      Object.keys(units.g).forEach((target) => {
        if (!measuresUnits[target]) {
          measures.push({
            amount: g / units.g[target],
            unit: target,
          });
          // if (debug) console.log('7 >>>>>', target, g / units.g[target]);
          measuresUnits[target] = true;
        }
      });

      measures.push({
        amount: g,
        unit: 'g',
      });
    }

    let sortedMeasures = measures;

    const sortPriorities = [
      'u', 'kg', 'g', 'l', 'ml',
      'xicara', 'colher-de-sopa',
      'colher-de-cha', 'colher-de-cafe',
    ];

    if (!unsorted) {
      sortedMeasures = _.sortBy(measures, ['unit']);
      sortedMeasures = _.sortBy(measures, ['amount']);

      sortedMeasures = _.sortBy(measures, (m) => sortPriorities.indexOf(m.unit));
    }

    return sortedMeasures;
  }
}

export default Measures;
