import * as PouchDB from 'pouchdb';
import { customRandom } from 'nanoid';
import * as seedrandom from 'seedrandom';

const InitPouch = PouchDB.default.defaults();

const GARNI_ENV = 'production';

class Storage {
  constructor(statusHandler) {
    if (window.location.hostname === 'localhost') {
      this.syncEnabled = false;
    } else {
      this.syncEnabled = true;
    }

    this.statusHandler = statusHandler;

    this.globalLocalDB = new InitPouch('garni:global');
    this.guestLocalDB = new InitPouch('garni:guest');
    this.userLocalDB = this.guestLocalDB;
    this.userRemoteDB = undefined;

    this.synchronizing = false;
    this.syncHandler = undefined;

    this.onEventCallbacks = {};

    window.addEventListener('storage', async (e) => {
      if (e && e.key === 'garni:tick') {
        await this.processCallbacks(undefined, true);
      }
    });
  }

  static databaseIdFor(seed) {
    const rng = seedrandom(seed);
    const databaseIdGenerator = customRandom(
      'abcdefghijklmnopqrstuvwxyz0123456789-',
      21 * 2,
      (size) => (new Uint8Array(size)).map(() => 256 * rng()),
    );

    return databaseIdGenerator();
  }

  startSync(userLocalId) {
    if (!this.syncEnabled) return;

    const databaseId = Storage.databaseIdFor(userLocalId);

    if (this.synchronizing === databaseId) return;

    if (this.synchronizing) {
      throw new Error(
        `already synchronizing to "${this.synchronizing}", can't start with ${databaseId}`,
      );
    }

    const databaseName = `garni_${databaseId}`;

    this.userLocalDB = new InitPouch(databaseName);

    this.synchronizing = databaseId;

    let auth = { username: 'garni', password: 'garni!' };

    let host = 'http://127.0.0.1:5984/';

    if (GARNI_ENV === 'production') {
      host = 'https://4mi30nkm47.execute-api.sa-east-1.amazonaws.com/';

      auth = {
        username: 'HBv7852OoSyoqnhs5uOeV7E03au44I',
        password: 'd9m36Y8Nk9XI8Duw3gj9wX8STyoEU9',
      };
    }

    this.userRemoteDB = new InitPouch(
      `${host}/${databaseName}`, { auth },
    );

    this.statusHandler('complete');

    this.syncHandler = this.userLocalDB.sync(this.userRemoteDB, { live: true, retry: true })
      .on('change', async (info) => {
        if (info.direction === 'push') this.statusHandler('complete');
        if (info.direction === 'pull') await this.processCallbacks(undefined, true);
      }).on('error', (e) => {
        this.statusHandler('error', e);
      });
  }

  async setItem(key, value, customDB) {
    let db = this.userLocalDB;
    if (customDB) db = customDB;

    let doc = await this.getDoc(key, db);

    if (doc !== null) {
      doc.content = value;
    } else {
      doc = { _id: key, content: value };
    }

    if (!['garni:global', 'garni:guest'].includes(db.name)) {
      this.statusHandler('pending');
    }

    localStorage.setItem('garni:tick', performance.now());

    await db.put(doc);
  }

  async getItem(key, customDB) {
    let db = this.userLocalDB;
    if (customDB) db = customDB;

    const doc = await this.getDoc(key, db);

    if (doc === null) return doc;

    return doc.content;
  }

  async removeItem(key, customDB) {
    let db = this.userLocalDB;
    if (customDB) db = customDB;

    const doc = await this.getDoc(key, db);

    if (doc === null) return doc;

    if (!['garni:global', 'garni:guest'].includes(db.name)) {
      this.statusHandler('pending');
    }

    localStorage.setItem('garni:tick', performance.now());

    return db.remove(doc);
  }

  async getDoc(key, customDB) {
    let db = this.userLocalDB;
    if (customDB) db = customDB;

    let doc;
    let getError;
    try {
      doc = await db.get(key);
    } catch (e) {
      getError = e;
    }

    if (getError !== undefined) {
      if (getError.name === 'not_found') {
        return null;
      }
      throw getError;
    }

    return doc;
  }

  onEvent(id, callback) {
    this.onEventCallbacks[id] = callback;
  }

  async processCallbacks(event, force) {
    const callbacks = Object.values(this.onEventCallbacks);

    for (let i = 0; i < callbacks.length; i += 1) {
      const callback = callbacks[i];

      /* eslint-disable no-await-in-loop */
      await callback(event, force);
      /* eslint-enable no-await-in-loop */
    }
  }

  // ----------------

  async getGlobalLocalItem(key) {
    return this.getItem(key, this.globalLocalDB);
    // return localStorage.getItem(key);
  }

  async setGlobalLocalItem(key, value) {
    return this.setItem(key, value, this.globalLocalDB);
    // return localStorage.setItem(key, value);
  }

  async removeGlobalLocalItem(key) {
    return this.removeItem(key, this.globalLocalDB);
    // return localStorage.removeItem(key);
  }

  // ----------------

  async getUser() {
    const rawUser = await this.getItem('user', this.globalLocalDB);

    if (rawUser) {
      if (!this.user) {
        this.startSync(rawUser.localId);
      }
      this.user = rawUser;
    }

    return this.user;
  }

  async removeUser() {
    this.user = null;

    await this.removeItem('user', this.guestLocalDB);
    await this.removeItem('user', this.globalLocalDB);

    if (this.synchronizing) {
      await this.syncHandler.cancel();
      delete this.syncHandler;
      this.synchronizing = false;
      await this.userLocalDB.destroy();

      this.statusHandler('none');
    }

    this.userLocalDB = this.guestLocalDB;

    await this.processCallbacks(undefined, true);

    return this.user;
  }

  async setUser(user) {
    if (this.user) return this.user;

    this.user = {
      accessToken: user.accessToken,
      displayName: user.displayName,
      photoURL: user.photoURL,
      email: user.email,
      localId: user.reloadUserInfo.localId,
    };

    await this.setItem('user', this.user, this.globalLocalDB);

    this.startSync(this.user.localId);

    await this.processCallbacks(undefined, true);

    return this.user;
  }
}

export default Storage;
