import _ from 'lodash';

class Conversions {
  static buildFor(baseUnits, key) {
    const units = { ...baseUnits };

    let from = key.split('/')[1];

    if (from === undefined) from = key;

    if (units[key] === null || units[key] === undefined) units[key] = {};

    const to = [];

    const hasDensity = (units[key].density !== undefined);

    if (hasDensity) {
      if (from === 'g') to.push('ml');
      if (from === 'ml') to.push('g');
    }

    if (units[key].g !== undefined) to.push('g');
    if (units[key].ml !== undefined) to.push('ml');

    if (units.ml && from !== 'ml') {
      const targets = Object.keys(units[key]);

      const possibleTargets = Object.keys(units.ml);

      for (let i = 0; i < targets.length; i += 1) {
        if (possibleTargets.includes(targets[i])) {
          to.push('ml');
        } else if (targets[i] !== 'density') {
          to.push(targets[i]);
        }
      }
    }

    if (units.g && from !== 'g') {
      const targets = Object.keys(units[key]);

      const possibleTargets = Object.keys(units.g);

      for (let i = 0; i < targets.length; i += 1) {
        if (possibleTargets.includes(targets[i])) {
          to.push('g');
        } else if (targets[i] !== 'density') {
          to.push(targets[i]);
        }
      }
    }

    this.checkForErrors(units, key, from, to);

    return { from, to: _.uniq(to) };
  }

  static checkForErrors(units, key, from, to) {
    if (to.includes(from)) {
      throw new Error(`Invalid conversion for ${key} (${from}->${from})`);
    }
  }
}

export default Conversions;
