class CheckModel {
  constructor(storage, closeAllToolbars, updateProgress) {
    this.closeAllToolbars = closeAllToolbars;
    this.updateProgress = updateProgress;
    this.storage = storage;

    this.checkables = {};
    this.stepCheckablesIds = {};
  }

  async reset() {
    const keys = Object.keys(this.checkables);

    for (let keyIndex = 0; keyIndex < keys.length; keyIndex += 1) {
      const check = this.checkables[keys[keyIndex]];

      /* eslint-disable no-await-in-loop */
      if (check.checked) await this.uncheck(check.id);
      /* eslint-enable no-await-in-loop */
    }

    this.updateProgress();
  }

  progress() {
    const keys = Object.keys(this.checkables);
    const total = keys.length;
    let checked = 0;

    for (let keyIndex = 0; keyIndex < keys.length; keyIndex += 1) {
      const check = this.checkables[keys[keyIndex]];

      if (check.checked) checked += 1;
    }

    return checked / total;
  }

  async check(id) {
    const check = this.checkables[id];
    check.checked = true;
    return this.storage.setItem(check.storageKey, 't');
  }

  async uncheck(id) {
    const check = this.checkables[id];
    check.checked = false;
    await this.storage.removeItem(check.storageKey);
  }

  async toggle(id, event, force) {
    if (event) event.stopPropagation();

    const check = this.checkables[id];

    this.closeAllToolbars();

    if (force === false || (check.checked && force !== true)) {
      await this.uncheck(id);
    } else {
      await this.check(id);
    }

    this.updateProgress();
  }

  async uncheckStep(stepId) {
    const ids = this.stepCheckablesIds[stepId];

    for (let idIndex = 0; idIndex < ids.length; idIndex += 1) {
      /* eslint-disable no-await-in-loop */
      await this.uncheck(ids[idIndex]);
      /* eslint-enable no-await-in-loop */
    }

    this.updateProgress();
  }

  async checkStep(stepId) {
    const ids = this.stepCheckablesIds[stepId];

    for (let idIndex = 0; idIndex < ids.length; idIndex += 1) {
      /* eslint-disable no-await-in-loop */
      await this.check(ids[idIndex]);
      /* eslint-enable no-await-in-loop */
    }

    this.updateProgress();
  }

  stepState(stepId) {
    const ids = this.stepCheckablesIds[stepId];
    const total = ids.length;
    let checked = 0;

    ids.forEach((id) => {
      if (this.checkables[id].checked) {
        checked += 1;
      }
    });

    if (checked === total) return 'done';

    if (checked > 0) return 'partial';

    return 'pending';
  }

  isChecked(id) {
    if (!this.checkables[id]) return false;

    return this.checkables[id].checked;
  }

  async buildFor(dish) {
    this.buildMapFor(dish);
    await this.loadState();
  }

  async loadState() {
    const keys = Object.keys(this.checkables);

    for (let keyIndex = 0; keyIndex < keys.length; keyIndex += 1) {
      const check = this.checkables[keys[keyIndex]];

      /* eslint-disable no-await-in-loop */
      if (await this.storage.getItem(check.storageKey) === 't') {
        check.checked = true;
      } else {
        check.checked = false;
      }
      /* eslint-enable no-await-in-loop */

      this.closeAllToolbars();
    }
  }

  buildMapFor(dish) {
    dish.recipes.forEach((recipe) => {
      recipe.steps.forEach((step) => {
        this.stepCheckablesIds[step.id] = [];

        if (step.ingredients) {
          step.ingredients.forEach((ingredient) => {
            if (this.checkables[ingredient.id] !== undefined) {
              throw new Error(`Duplicated check id: "${ingredient.id}"`);
            }

            this.stepCheckablesIds[step.id].push(ingredient.id);

            this.checkables[ingredient.id] = {
              id: ingredient.id,
              kind: 'ingredient',
              storageKey: `dish:${dish.id}:${ingredient.id}/igc`,
              checked: false,
            };

            if (ingredient['mise-en-place']) {
              ingredient['mise-en-place'].forEach((miseEnPlace) => {
                if (this.checkables[miseEnPlace.id] !== undefined) {
                  throw new Error(`Duplicated check id: "${miseEnPlace.id}"`);
                }

                this.stepCheckablesIds[step.id].push(miseEnPlace.id);

                this.checkables[miseEnPlace.id] = {
                  id: miseEnPlace.id,
                  kind: 'mise-en-place',
                  storageKey: `dish:${dish.id}:${miseEnPlace.id}/mpc`,
                  checked: false,
                };
              });
            }
          });
        }

        if (step.instructions) {
          step.instructions.forEach((instruction) => {
            if (this.checkables[instruction.id] !== undefined) {
              throw new Error(`Duplicated check id: "${instruction.id}"`);
            }

            this.stepCheckablesIds[step.id].push(instruction.id);

            this.checkables[instruction.id] = {
              id: instruction.id,
              kind: 'instruction',
              storageKey: `dish:${dish.id}:${instruction.id}/itc`,
              checked: false,
            };
          });
        }
      });
    });
  }
}

export default CheckModel;
