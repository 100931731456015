import _ from 'lodash';

class ToDoModel {
  static async saveOrder(storage, listId, ids) {
    const list = await this.loadList(storage, listId);

    for (let i = 0; i < list.items.length; i += 1) {
      list.items[i].position = _.findIndex(ids, (id) => id === list.items[i].id);
    }

    return this.saveList(storage, listId, list);
  }

  static async addIngredient(storage, listId, dishId, dishPath, sharedIngredientsInput) {
    const sharedIngredients = [...sharedIngredientsInput.map((i) => ({
      ...{ dishId, 'dish-id': dishId, sharedId: i['shared-id'] },
      ...i,
    }))];

    const ingredientSample = sharedIngredients[0];

    const ingredientSharedId = ingredientSample['shared-id'];

    const ingredientGroceryKey = `ingredient:${dishId}:${ingredientSample['shared-id']}/g`;

    await storage.setItem(ingredientGroceryKey, 'p');

    const list = await this.loadList(storage, listId);

    const indexWithDish = _.findIndex(list.items, (i) => (
      i.ingredientSharedId === ingredientSharedId
        && i.dishIds.includes(dishId)
    ));

    const indexIngredient = _.findIndex(list.items, (i) => (
      i.ingredientSharedId === ingredientSharedId
    ));

    let itemId;

    if (indexIngredient > -1 && indexWithDish < 0) {
      itemId = list.items[indexIngredient].id;

      list.items[indexIngredient].dishIds.push(dishId);
      list.items[indexIngredient].dishPath[dishId] = dishPath;
      list.items[indexIngredient].sharedIngredients = [
        ...list.items[indexIngredient].sharedIngredients,
        ...sharedIngredients,
      ];
    } else if (indexWithDish < 0 && indexIngredient < 0) {
      itemId = list.lastId + 1;
      list.lastId = itemId;

      const dishPathObject = {};

      dishPathObject[dishId] = dishPath;

      list.items.push({
        id: itemId,
        title: ingredientSample.name,
        done: false,

        dishIds: [dishId],
        dishPath: dishPathObject,
        ingredientSharedId,
        sharedIngredients,

        position: undefined,
      });
    }

    let updatedList = await this.saveList(storage, listId, list);

    if (_.find(list.items, (i) => i.id === itemId).done) {
      updatedList = await this.toggle(storage, listId, itemId);
    }

    await this.tick(storage, listId);

    return updatedList;
  }

  static async removeIngredient(storage, listId, dishId, ingredient) {
    const ingredientGroceryKey = `ingredient:${dishId}:${ingredient['shared-id']}/g`;

    await storage.removeItem(ingredientGroceryKey);

    const list = await this.loadList(storage, listId);

    const index = _.findIndex(list.items, (i) => (
      i.ingredientSharedId === ingredient['shared-id']
        && i.dishIds.includes(dishId)
    ));

    if (index > -1) {
      list.items[index].dishIds = _.filter(list.items[index].dishIds, (id) => id !== dishId);

      list.items[index].sharedIngredients = _.filter(
        list.items[index].sharedIngredients, (i) => i.dishId !== dishId,
      );

      let updatedList;

      if (list.items[index].dishIds.length === 0) {
        updatedList = await this.remove(storage, listId, list.items[index].id);
      } else {
        updatedList = await this.saveList(storage, listId, list);
      }

      await this.tick(storage, listId);

      return updatedList;
    }

    return list;
  }

  static async tick(storage, listId) {
    await storage.setItem(`todo:${listId}/tk`, performance.now());
  }

  static async toggle(storage, listId, itemId) {
    const list = await this.loadList(storage, listId);

    const index = _.findIndex(list.items, (i) => i.id === itemId);

    list.items[index].done = !list.items[index].done;

    const item = list.items[index];

    if (item.ingredientSharedId) {
      for (let i = 0; i < item.dishIds.length; i += 1) {
        const dishId = item.dishIds[i];

        const ingredientGroceryKey = `ingredient:${dishId}:${item.ingredientSharedId}/g`;

        /* eslint-disable no-await-in-loop */
        if (list.items[index].done) {
          await storage.setItem(ingredientGroceryKey, 'd');
        } else {
          await storage.setItem(ingredientGroceryKey, 'p');
        }
        /* eslint-enable no-await-in-loop */
      }
    }

    return this.saveList(storage, listId, list);
  }

  static async removeAll(storage, listId) {
    const list = await this.loadList(storage, listId);

    for (let i = 0; i < list.items.length; i += 1) {
      const item = list.items[i];

      /* eslint-disable no-await-in-loop */
      await this.remove(storage, listId, item.id);
      /* eslint-enable no-await-in-loop */
    }

    list.items = [];
    list.lastId = 0;

    return this.saveList(storage, listId, list);
  }

  static async remove(storage, listId, itemId) {
    const list = await this.loadList(storage, listId);

    const index = _.findIndex(list.items, (i) => i.id === itemId);

    if (index > -1) {
      const item = list.items[index];

      if (item.ingredientSharedId) {
        for (let i = 0; i < item.dishIds.length; i += 1) {
          const dishId = item.dishIds[i];

          const ingredientGroceryKey = `ingredient:${dishId}:${item.ingredientSharedId}/g`;

          /* eslint-disable no-await-in-loop */
          await storage.removeItem(ingredientGroceryKey);
          /* eslint-enable no-await-in-loop */
        }
      }
    }

    list.items = _.filter(list.items, (i) => i.id !== itemId);

    if (list.items.length === 0) list.lastId = 0;

    return this.saveList(storage, listId, list);
  }

  static async updateTitle(storage, listId, itemId, newTitle) {
    const list = await this.loadList(storage, listId);

    const index = _.findIndex(list.items, (i) => i.id === itemId);

    list.items[index].title = newTitle;

    return this.saveList(storage, listId, list);
  }

  static async add(storage, listId, title) {
    const list = await this.loadList(storage, listId);

    const itemId = list.lastId + 1;
    list.lastId = itemId;

    list.items.push({
      id: itemId,
      title,
      done: false,

      dishIds: [],
      dishPath: {},

      ingredientSharedId: null,
      sharedIngredients: [],

      position: undefined,
    });

    return this.saveList(storage, listId, list);
  }

  static async saveList(storage, id, list) {
    const storageKey = `todo:${id}`;

    await storage.setItem(storageKey, list);

    return this.loadList(storage, id);
  }

  static async loadList(storage, id) {
    const storageKey = `todo:${id}`;

    let list = await storage.getItem(storageKey);

    if (list === null) {
      list = {
        lastId: 0,
        items: [],
      };
    }

    list.items = _.sortBy(list.items, ['done', 'position', 'id']);

    const done = _.filter(list.items, ['done', true]);
    const pending = _.filter(list.items, ['done', false]);

    list.items = [...done, ...pending];

    return list;
  }

  /* eslint-disable no-param-reassign */
  static async updateGroceryState(storage, vueInstance) {
    const list = await this.loadList(storage, 'grocery');

    const done = _.filter(list.items, ['done', true]);
    const pending = _.filter(list.items, ['done', false]);

    vueInstance.groceryState.total = list.items.length;
    vueInstance.groceryState.done = done.length;
    vueInstance.groceryState.pending = pending.length;
  }
  /* eslint-enable no-param-reassign */
}

export default ToDoModel;
