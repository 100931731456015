<template>
  <div class="dish">
    <div class="load-error base-box" v-if="loadError">
      {{loadError}}
    </div>
    <div v-if="dish && !loadError">
      <h1>
        {{ dish.name }}
      </h1>

      <div v-if="showDishFactor" class="dish-factor">
        <div class="current-factor">
          {{presentedFactor}}
          <button v-if="!openFactor" @click="adjust" class="adjust adjust-open">
            <i class="bi bi-pencil"></i>
          </button>
        </div>

        <Factor
          v-if="openFactor"
          :factor="factor"
          :baseAmount="1.0"
          unit="u"
          :highPrecision="highPrecision"
          />

          <button v-if="openFactor" @click="adjust" class="adjust adjust-close">
            <i class="bi bi-x-lg"></i>
          </button>
      </div>

      <div
        class="recipe base-box"
        v-for="(recipe, recipeIndex) in dish.recipes"
        :key="`r-${recipeIndex}`">
        <div class="recipe-toolbar">
          <button
            v-if="showRecipeLockToggle"
            @click="toggleRecipeLock(recipeIndex)"
            type="button"
            class="btn btn-warning btn-sm">
            <i v-if="recipeLockState(recipeIndex) === 'open'" class="bi bi-unlock"></i>
            <i v-if="recipeLockState(recipeIndex) === 'partial'" class="bi bi-lock"></i>
            <i v-if="recipeLockState(recipeIndex) === 'full'" class="bi bi-lock-fill"></i>
          </button>
        </div>

        <h2 v-if="
          recipe.title
          // && (
          //   recipe.steps.length > 1
          //   ||
          //   recipe.steps[0].title
          // )
        ">{{recipe.title}}</h2>

        <div v-for="(step, stepIndex) in recipe.steps" :key="`r-${recipeIndex}-s-${stepIndex}`">
          <div :class="classForStep(step.id, recipeIndex, stepIndex)">
            <h3>
              <div
                class="number longpress"
                :data-step="step.id"
                @click="askAndToggleStepChecks(step.id, $event)">
                <span
                  v-if="recipe.steps.length > 1"
                  :data-step="step.id">
                  {{stepIndex+1}}
                </span>
                <span v-else :data-step="step.id">
                  &nbsp;
                </span>
                <div class="check-step">
                  <div class="form-check">
                    <input
                      @click="askAndToggleStepChecks(step.id, $event)"
                      :id="`step-check-${step.id}`"
                      class="form-check-input"
                      :data-step="step.id"
                      :data-state="stepCheckState(step.id)"
                      type="checkbox">
                  </div>
                </div>
              </div>
              <span
                v-if="step.title"
                class="title-text longpress"
                :data-step="step.id"
                @click="askAndToggleStepChecks(step.id, $event)">
                {{step.title}}
              </span>
              <span
                v-else
                class="title-text longpress"
                :data-step="step.id"
                @click="askAndToggleStepChecks(step.id, $event)">
                <span v-if="recipe.title">
                  {{recipe.title}}
                </span>
                <span v-else>
                  {{dish.name}}
                </span>
              </span>

              <div class="lock-step" v-if="showStepLockToggle && recipe.steps.length > 1">
                <button
                  v-if="step.ingredients && step.ingredients.length > 0"
                  @click="toggleStepLock(recipeIndex, stepIndex)"
                  type="button"
                  class="btn btn-warning btn-sm">
                  <i v-if="stepLocked(recipeIndex, stepIndex)" class="bi bi-lock-fill"></i>
                  <i v-else class="bi bi-unlock"></i>
                </button>
                <button disabled v-else class="btn no-lock btn-light btn-sm">
                  <i v-if="recipeLockState(recipeIndex) === 'open'" class="bi bi-unlock"></i>
                  <i v-if="recipeLockState(recipeIndex) === 'partial'" class="bi bi-lock"></i>
                  <i v-if="recipeLockState(recipeIndex) === 'full'" class="bi bi-lock-fill"></i>
                </button>
              </div>
            </h3>
            <div class="container">
              <div class="row">
                <div class="col-lg">

                  <div
                    class="no-list"
                    v-if="!step.ingredients || step.ingredients.length === 0">
                    Sem ingredientes neste passo.
                  </div>

                  <Ingredient
                    v-for="(ingredient, ingredientIndex) in step.ingredients"
                    :ref="`r-${recipeIndex}-s-${stepIndex}-i-${ingredientIndex}-${ingredient.id}`"
                    :highPrecision="highPrecision"
                    :key="`r-${recipeIndex}-s-${stepIndex}-i-${ingredientIndex}-${ingredient.id}`"
                    :units="units"
                    :recipe-index="recipeIndex"
                    :step-index="stepIndex"
                    :dish-id="dish.id"
                    :dish-path="dish.path"
                    :display="display"
                    :microMoment="microMoment"
                    :factor="stepFactor(recipeIndex, stepIndex)"
                    :locked="stepLocked(recipeIndex, stepIndex)"
                    :ingredient="ingredient" />
                </div>
                <div class="col-lg">
                  <div v-if="showInstructions">
                    <div
                      v-for="(instruction, instructionIndex) in step.instructions"
                      :key="`instruction-${instruction.id}`"
                      @click="toggleInstructionCheck(instruction.id, $event)">
                      <div :class="stepInstructionClass(instruction.id)">
                        <div class="step-check">
                          <div class="form-check">
                            <input
                              :checked="instructionChecked(instruction.id)"
                              class="form-check-input"
                              type="checkbox">
                          </div>
                        </div>
                        <div class="letter-container">
                          <div class="letter">
                            {{alphabet[instructionIndex]}}
                          </div>
                        </div>
                        <div class="instruction">
                          {{instruction.description}}
                        </div>
                      </div>
                      <div :class="systemClass(instruction.id)" v-if="instruction.system">
                        <SystemDoneness
                            :done="instructionChecked(instruction.id)"
                            :path="instruction.system.import"
                            :settings="instruction.system" />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="showInstructions && (
                      !step.instructions || step.instructions.length === 0
                    )"
                    class="no-list">
                    Sem instruções neste passo.
                  </div>
                  <div
                    v-if="!showInstructions && step.instructions && step.instructions.length > 0"
                    class="no-list">
                    Instruções ocultas.
                  </div>
                </div>
              </div>
              <div :class="checkStepClass">
                <button
                  type="button"
                  class="btn btn-success longpress"
                  :data-step="step.id"
                  @click="askAndToggleStepChecks(step.id, $event)">
                  <div class="form-check"
                    :data-step="step.id">
                    <input
                      @click="askAndToggleStepChecks(step.id, $event)"
                      :id="`step-check-${step.id}-b`"
                      class="form-check-input"
                      :data-step="step.id"
                      :data-state="stepCheckState(step.id)"
                      type="checkbox">
                      completar
                  </div>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="reset-area">
      <button
        v-if="showClearSettings"
        @click="clearSettings"
        type="button" class="btn reset-button btn-danger btn-sm"
        title="ALT + L">resetar</button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import * as axios from 'axios';
import TinyGesture from 'tinygesture';

import { inject, provide } from 'vue';

import Loader from '../alchimia/loader';
import Presenter from '../alchimia/presenter';

import BookmarkModel from '../models/bookmark';
import CheckModel from '../models/check';

import Ingredient from '../components/Ingredient.vue';
import Factor from '../components/Factor.vue';

import SystemDoneness from '../components/systems/Doneness.vue';

export default {
  name: 'Dish',
  components: {
    Ingredient, Factor, SystemDoneness,
  },
  props: {
    path: String,
    units: Object,
    display: Object,
    highPrecision: Boolean,
  },
  data() {
    return {
      defaultMicroMoment: 'explore',
      microMoment: 'explore',
      microMoments: [
        'explore',
        'plan',
        'stock',
        'miseEnPlace',
        'cook',
      ],
      longpressPending: false,
      bookmark: false,
      factor: 1.0,
      dish: undefined,
      openFactor: false,
      ingredientsRefs: [],
      debouncedSaveFactor: _.debounce(this.saveFactor, 100),
      cachedLocks: {},
      loadError: null,
      alphabet: [...'abcdefghijklmnopqrstuvwxyz'],
      checkModel: undefined,
    };
  },
  beforeUnmount() {
    this.footer().releaseMicroMoment();
    this.footer().releaseBookmark();
    this.footer().releaseProgress();
  },
  async created() {
    provide('inputFocus', () => {});
    provide('updateDishFactor', this.updateDishFactor);
    provide('updateFactor', this.updateDishFactor);
    provide('closeAllToolbars', this.closeAllToolbars);

    provide('ingredientChecked', this.ingredientChecked);
    provide('toggleIngredientCheck', this.toggleIngredientCheck);

    provide('miseEnPlaceChecked', this.miseEnPlaceChecked);
    provide('toggleMiseEnPlaceCheck', this.toggleMiseEnPlaceCheck);

    provide('updateIngredientsGrocery', this.updateIngredientsGrocery);
    provide('updateIngredientsUnit', this.updateIngredientsUnit);
    provide('sharedIngredients', this.sharedIngredients);
    provide('lockStepFactorAs', this.lockStepFactorAs);

    this.storage = inject('storage');
    this.goTo = inject('goTo');
    this.footer = inject('footer');
    this.registerOnStorage = inject('registerOnStorage');
    this.pageLoaded = inject('pageLoaded');
    this.confirmModal = inject('confirmModal');

    await this.loadDish();
  },
  methods: {
    updateProgress() {
      this.footer().setProgress(this.checkModel.progress());
    },
    async dishLoaded() {
      this.checkModel = new CheckModel(
        this.storage,
        this.closeAllToolbars,
        this.updateProgress,
      );

      await this.checkModel.buildFor(this.dish);

      this.updateProgress();

      await this.loadFactor();
      await this.loadMicroMoment();

      this.buildIngredientsRefs();
      await this.loadStepLocks();

      this.pageLoaded();

      this.bookmark = await BookmarkModel.is(this.storage, this.dish.id);

      this.footer().setBookmark(this.bookmark, this.toggleBookmark);

      setTimeout(() => { this.buildGestures(); }, 0);

      window.addEventListener('keydown', this.onKeydown);

      this.registerOnStorage('dish', this.storageUpdated);
    },
    sharedIngredients(ingredientSharedId) {
      const ingredients = [];

      this.dish.recipes.forEach((recipe) => {
        if (recipe.steps) {
          recipe.steps.forEach((step) => {
            if (step.ingredients) {
              step.ingredients.forEach((ingredient) => {
                if (ingredient['shared-id'] === ingredientSharedId) {
                  ingredients.push(ingredient);
                }
              });
            }
          });
        }
      });

      return ingredients;
    },
    buildGestures() {
      document.getElementsByClassName('longpress').forEach((target) => {
        const gesture = new TinyGesture(target);

        gesture.on('longpress', async (event) => {
          this.longpressPending = true;

          const stepId = event.target.dataset.step;

          await this.askAndToggleStepChecks(stepId, event, true);
        });
      });
    },
    systemClass(id) {
      if (this.instructionChecked(id)) {
        return 'item-done';
      }
      return '';
    },
    stepInstructionClass(id) {
      if (this.instructionChecked(id)) {
        return 'step-instruction item-done';
      }
      return 'step-instruction';
    },
    classForStep(stepId, recipeIndex, stepIndex) {
      const state = this.stepCheckState(stepId, 0, true);

      const recipe = this.dish.recipes[recipeIndex];
      const step = recipe.steps[stepIndex];

      let prefix = '';

      if (!step.title && recipe.steps.length < 2) {
        prefix = 'irrelevant';
      }

      if (state === 'done') {
        return `step step-done ${prefix}`;
      }

      return `step ${prefix}`;
    },
    async askAndToggleStepChecks(stepId, event, fromLongpress) {
      if (event) event.stopPropagation();

      if (!fromLongpress && this.longpressPending) {
        this.longpressPending = false;
        this.stepCheckState(stepId, 0, false);
        return;
      }

      const state = this.stepCheckState(stepId, 0, true);

      if (state === 'partial') {
        if (fromLongpress) {
          await this.toggleStepChecks(stepId, state);
        } else {
          await this.confirmModal().open(
            'Completar todos os itens deste passo?',
            async () => {
              await this.toggleStepChecks(stepId, state);
            },
          );
        }
      } else {
        await this.toggleStepChecks(stepId, state);
      }
    },
    async toggleStepChecks(stepId, stepState) {
      if (stepState === 'done') {
        this.checkModel.uncheckStep(stepId);
      } else {
        this.checkModel.checkStep(stepId);
      }
    },
    stepCheckState(stepId, limitReference, ignoreUI) {
      let limit = limitReference;

      if (limit === undefined) limit = 0;

      if (limit > 100) {
        throw new Error('stack too deep');
      }

      const elementId = `step-check-${stepId}`;
      const elementIdB = `step-check-${stepId}-b`;

      const checkbox = document.getElementById(elementId);
      const checkboxB = document.getElementById(elementIdB);

      const state = this.checkModel.stepState(stepId);

      if (!ignoreUI && !checkbox) {
        setTimeout(() => { this.stepCheckState(stepId, limit + 1); }, 0);
        return '';
      }

      if (checkbox) {
        if (checkbox && state === 'done') {
          checkbox.checked = true;
          checkbox.indeterminate = false;

          checkboxB.checked = true;
          checkboxB.indeterminate = false;
        } else if (state === 'partial') {
          checkbox.indeterminate = true;
          checkboxB.indeterminate = true;
        } else if (state === 'pending') {
          checkbox.indeterminate = false;
          checkbox.checked = false;

          checkboxB.indeterminate = false;
          checkboxB.checked = false;
        }
      }

      return state;
    },
    // TODO: Refactor A \/
    async toggleInstructionCheck(id, event, force) {
      await this.checkModel.toggle(id, event, force);
    },
    async toggleIngredientCheck(id, event, force) {
      await this.checkModel.toggle(id, event, force);
    },
    async toggleMiseEnPlaceCheck(id, event, force) {
      await this.checkModel.toggle(id, event, force);
    },
    miseEnPlaceChecked(id) {
      return this.checkModel.isChecked(id);
    },
    ingredientChecked(id) {
      return this.checkModel.isChecked(id);
    },
    instructionChecked(id) {
      return this.checkModel.isChecked(id);
    },
    // TODO: Refactor A /\

    async toggleBookmark(event) {
      if (event) event.stopPropagation();

      if (this.bookmark) {
        await BookmarkModel.remove(this.storage, this.dish.id);
        this.bookmark = false;
      } else {
        await BookmarkModel.add(this.storage, this.dish.id);
        this.bookmark = true;
      }

      this.footer().setBookmark(this.bookmark, this.toggleBookmark);
    },
    async onKeydown(e) {
      if (e.altKey && ['l', 'L'].includes(e.key)) {
        await this.clearSettings();
      }
    },
    async updateIngredientsGrocery() {
      for (
        let ingredientIndex = 0;
        ingredientIndex < this.ingredientsRefs.length;
        ingredientIndex += 1
      ) {
        const ingredientsRef = this.ingredientsRefs[ingredientIndex];
        if (this.$refs[ingredientsRef]) {
          /* eslint-disable no-await-in-loop */
          await this.$refs[ingredientsRef].loadGrocery();
          /* eslint-enable no-await-in-loop */
        }
      }
    },
    async updateIngredientsUnit() {
      for (let refIndex = 0; refIndex < this.ingredientsRefs.length; refIndex += 1) {
        const ingredientsRef = this.ingredientsRefs[refIndex];

        if (this.$refs[ingredientsRef]) {
          /* eslint-disable no-await-in-loop */
          await this.$refs[ingredientsRef].loadUnit();
          /* eslint-enable no-await-in-loop */
        }
      }
    },
    async storageUpdated() {
      await this.dishLoaded();
      await this.updateIngredientsGrocery();
      await this.updateIngredientsUnit();
    },
    async toggleMicroMoment(event) {
      if (event) event.stopPropagation();

      this.closeAllToolbars();

      let index = _.findIndex(this.microMoments, ((mm) => mm === this.microMoment));

      if (index === -1) {
        this.microMoment = this.defaultMicroMoment;
      } else {
        index += 1;

        if (index >= this.microMoments.length) {
          index = 0;
        }

        this.microMoment = this.microMoments[index];
      }

      await this.storage.setItem(this.keys.microMoment, this.microMoment);

      this.footer().setMicroMoment(this.microMoment, this.toggleMicroMoment);
    },
    async clearSettings() {
      await this.confirmModal().open(
        'Deseja apagar todas as configurações que você fez e voltar para os valores padrões?',
        async () => {
          this.closeAllToolbars();

          await this.storage.removeItem(this.keys.microMoment);

          await this.storage.removeItem(this.keys.factor);

          this.factor = 1.0;

          for (let refIndex = 0; refIndex < this.ingredientsRefs.length; refIndex += 1) {
            const ingredientsRef = this.ingredientsRefs[refIndex];

            if (this.$refs[ingredientsRef]) {
              /* eslint-disable no-await-in-loop */
              await this.$refs[ingredientsRef].resetUnit();
              /* eslint-enable no-await-in-loop */
            }
          }

          for (let recipeIndex = 0; recipeIndex < this.dish.recipes.length; recipeIndex += 1) {
            /* eslint-disable no-await-in-loop */
            await this.unlockRecipe(recipeIndex);
            /* eslint-enable no-await-in-loop */
          }

          await this.checkModel.reset();

          this.microMoment = this.defaultMicroMoment;

          this.footer().setBookmark(this.bookmark, this.toggleBookmark);
          this.footer().setMicroMoment(this.microMoment, this.toggleMicroMoment);
        },
      );
    },
    async loadMicroMoment() {
      const storedMicroMoment = await this.storage.getItem(this.keys.microMoment);

      if (storedMicroMoment !== null) {
        this.microMoment = storedMicroMoment;
      } else {
        this.microMoment = this.defaultMicroMoment;
      }

      this.footer().setMicroMoment(this.microMoment, this.toggleMicroMoment);
    },
    async loadFactor() {
      const storedFactor = await this.storage.getItem(this.keys.factor);

      if (storedFactor !== null) this.factor = parseFloat(storedFactor);
    },
    async loadStepLocks() {
      for (let recipeIndex = 0; recipeIndex < this.dish.recipes.length; recipeIndex += 1) {
        const recipe = this.dish.recipes[recipeIndex];

        for (let stepIndex = 0; stepIndex < recipe.steps.length; stepIndex += 1) {
          const step = recipe.steps[stepIndex];

          if (step.ingredients) {
            for (
              let ingredientIndex = 0;
              ingredientIndex < step.ingredients.length;
              ingredientIndex += 1
            ) {
              const ingredient = step.ingredients[ingredientIndex];

              const lockKey = `dish:${this.dish.id}:${ingredient.id}/fx`;

              /* eslint-disable no-await-in-loop */
              const factor = await this.storage.getItem(lockKey);
              /* eslint-enable no-await-in-loop */
              if (factor !== undefined && factor !== null) {
                this.cachedLocks[lockKey] = parseFloat(factor);
              } else {
                delete this.cachedLocks[lockKey];
              }
            }
          }
        }
      }
    },
    closeAllToolbars() {
      this.ingredientsRefs.forEach((ingredientsRef) => {
        if (this.$refs[ingredientsRef]) {
          this.$refs[ingredientsRef].closeToolbar();
        }
      });
      this.openFactor = false;
    },
    buildIngredientsRefs() {
      const ingredientsRefs = [];
      for (let recipeIndex = 0; recipeIndex < this.dish.recipes.length; recipeIndex += 1) {
        const recipe = this.dish.recipes[recipeIndex];
        for (let stepIndex = 0; stepIndex < recipe.steps.length; stepIndex += 1) {
          const step = recipe.steps[stepIndex];
          if (step.ingredients) {
            for (
              let ingredientIndex = 0;
              ingredientIndex < step.ingredients.length;
              ingredientIndex += 1
            ) {
              const ingredient = step.ingredients[ingredientIndex];
              ingredientsRefs.push(
                `r-${recipeIndex}-s-${stepIndex}-i-${ingredientIndex}-${ingredient.id}`,
              );
            }
          }
        }
      }

      this.ingredientsRefs = ingredientsRefs;
    },
    adjust() {
      if (this.openFactor) {
        this.openFactor = false;
      } else {
        this.closeAllToolbars();
        this.openFactor = true;
      }
    },
    updateDishFactor(newFactor) {
      this.factor = newFactor;
      this.debouncedSaveFactor();
    },
    async saveFactor() {
      await this.storage.setItem(this.keys.factor, this.factor);
    },
    async loadDish() {
      const vueThis = this;
      await axios.get(`built/dishes/${this.path}.yml`)
        .then((response) => {
          try {
            vueThis.dish = Loader.parseYaml(response.data);
            vueThis.dishLoaded().then(() => {
              vueThis.loadError = null;
            });
          } catch (error) {
            this.loadError = error;
          }
        })
        .catch((error) => {
          this.loadError = error;
        });
    },
    stepFactor(recipeIndex, stepIndex) {
      const step = this.dish.recipes[recipeIndex].steps[stepIndex];

      if (!step.ingredients) return false;

      const ingredient = step.ingredients[0];

      const lockKey = `dish:${this.dish.id}:${ingredient.id}/fx`;

      if (
        this.cachedLocks[lockKey] !== null
        && this.cachedLocks[lockKey] !== undefined
      ) {
        return this.cachedLocks[lockKey];
      }

      return this.factor;
    },
    recipeLockState(recipeIndex) {
      const stepsLength = this.dish.recipes[recipeIndex]
        .steps.filter((recipe) => recipe.ingredients && recipe.ingredients.length > 0).length;

      let lockedSteps = 0;

      const stepsToCheck = this.dish.recipes[recipeIndex].steps.length;

      for (let stepIndex = 0; stepIndex < stepsToCheck; stepIndex += 1) {
        if (this.stepLocked(recipeIndex, stepIndex)) lockedSteps += 1;
      }

      if (lockedSteps === 0) {
        return 'open';
      } if (stepsLength === lockedSteps) {
        return 'full';
      }
      return 'partial';
    },
    async unlockRecipe(recipeIndex) {
      const stepsLength = this.dish.recipes[recipeIndex].steps.length;

      for (let stepIndex = 0; stepIndex < stepsLength; stepIndex += 1) {
        /* eslint-disable no-await-in-loop */
        await this.unlockStep(recipeIndex, stepIndex);
        /* eslint-enable no-await-in-loop */
      }
    },
    async lockRecipe(recipeIndex) {
      const stepsLength = this.dish.recipes[recipeIndex].steps.length;

      for (let stepIndex = 0; stepIndex < stepsLength; stepIndex += 1) {
        /* eslint-disable no-await-in-loop */
        await this.lockStep(recipeIndex, stepIndex);
        /* eslint-enable no-await-in-loop */
      }
    },
    async toggleRecipeLock(recipeIndex) {
      const state = this.recipeLockState(recipeIndex);

      if (state === 'full') {
        this.confirmModal().open(
          'Deseja realmente destravar todos os ingredientes?',
          async () => { await this.unlockRecipe(recipeIndex); },
        );
      } else {
        await this.lockRecipe(recipeIndex);
      }
    },
    async lockStepFactorAs(recipeIndex, stepIndex, value) {
      const step = this.dish.recipes[recipeIndex].steps[stepIndex];

      if (!step.ingredients) return;

      for (
        let ingredientIndex = 0;
        ingredientIndex < step.ingredients.length;
        ingredientIndex += 1
      ) {
        const ingredient = step.ingredients[ingredientIndex];

        const lockKey = `dish:${this.dish.id}:${ingredient.id}/fx`;

        this.cachedLocks[lockKey] = value;
        /* eslint-disable no-await-in-loop */
        await this.storage.setItem(lockKey, value);
        /* eslint-enable no-await-in-loop */
      }
    },
    async lockStep(recipeIndex, stepIndex) {
      const step = this.dish.recipes[recipeIndex].steps[stepIndex];

      if (!step.ingredients) return;

      for (
        let ingredientIndex = 0;
        ingredientIndex < step.ingredients.length;
        ingredientIndex += 1
      ) {
        const ingredient = step.ingredients[ingredientIndex];

        const lockKey = `dish:${this.dish.id}:${ingredient.id}/fx`;

        this.cachedLocks[lockKey] = this.factor;
        /* eslint-disable no-await-in-loop */
        await this.storage.setItem(lockKey, this.factor);
        /* eslint-enable no-await-in-loop */
      }
    },
    async unlockStep(recipeIndex, stepIndex) {
      const step = this.dish.recipes[recipeIndex].steps[stepIndex];

      if (!step.ingredients) return;

      for (
        let ingredientIndex = 0;
        ingredientIndex < step.ingredients.length;
        ingredientIndex += 1
      ) {
        const ingredient = step.ingredients[ingredientIndex];

        const lockKey = `dish:${this.dish.id}:${ingredient.id}/fx`;

        delete this.cachedLocks[lockKey];

        /* eslint-disable no-await-in-loop */
        await this.storage.removeItem(lockKey);
        /* eslint-enable no-await-in-loop */
      }
    },
    async toggleStepLock(recipeIndex, stepIndex) {
      if (this.stepLocked(recipeIndex, stepIndex)) {
        await this.confirmModal().open(
          'Deseja realmente destravar os ingredientes?',
          async () => { await this.unlockStep(recipeIndex, stepIndex); },
        );
      } else {
        await this.lockStep(recipeIndex, stepIndex);
      }
    },
    stepLocked(recipeIndex, stepIndex) {
      const step = this.dish.recipes[recipeIndex].steps[stepIndex];

      if (!step.ingredients) return false;

      const ingredient = step.ingredients[0];

      const lockKey = `dish:${this.dish.id}:${ingredient.id}/fx`;

      return (
        this.cachedLocks[lockKey] !== null
        && this.cachedLocks[lockKey] !== undefined
      );
    },
  },
  computed: {
    checkStepClass() {
      if (this.showIngredientsCheck) return 'check-step-bottom';

      return 'check-step-bottom d-none';
    },
    showIngredientsCheck() {
      return {
        explore: true,
        plan: false,
        stock: false,
        miseEnPlace: false,
        cook: true,
      }[this.microMoment];
    },
    showDishFactor() {
      return {
        explore: true,
        plan: true,
        stock: false,
        miseEnPlace: false,
        cook: false,
      }[this.microMoment];
    },
    showRecipeLockToggle() {
      return this.showStepLockToggle;
    },
    showStepLockToggle() {
      return {
        explore: true,
        plan: true,
        stock: false,
        miseEnPlace: false,
        cook: false,
      }[this.microMoment];
    },
    showClearSettings() {
      return {
        explore: true,
        plan: true,
        stock: false,
        miseEnPlace: false,
        cook: false,
      }[this.microMoment];
    },
    showInstructions() {
      return {
        explore: true,
        plan: false,
        stock: false,
        miseEnPlace: false,
        cook: true,
      }[this.microMoment];
    },
    keys() {
      return {
        microMoment: 'dish:mm',
        factor: `dish:${this.dish.id}/fx`,
        stepLock: `dish:${this.dish.id}/sl`,
      };
    },
    presentedFactor() {
      return Presenter.toFraction(this.factor, this.highPrecision);
    },
  },
};
</script>
