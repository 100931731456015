import _ from 'lodash';

import Units from './units';
import Conversions from './conversions';

class Density {
  static calculateFor(baseUnits, key) {
    const units = { ...baseUnits };

    if (units[key] === null || units[key] === undefined) units[key] = {};

    const conversions = Conversions.buildFor(units, key);

    if (!Units.STANDARDS.includes(conversions.from)) {
      // TODO: Interesting, but not useful right now.
      // return this.internalDensity(units, key, conversions);
      return undefined;
    }

    const densities = [];

    if (units[key].density !== undefined) {
      densities.push(units[key].density);
    }

    conversions.to.forEach((target) => {
      if (units[key][target] !== undefined && Units.STANDARDS.includes(target)) {
        densities.push(units[key][target]);
      }
    });

    if (conversions.from === 'g') {
      Object.keys(units[key]).forEach((target) => {
        if (Object.keys(units.ml).includes(target)) {
          const g = units[key][target];
          const ml = units.ml[target];

          densities.push(ml / g);
        }
      });
    }

    if (densities.length === 0) return undefined;

    const densitiesCheck = densities.map((d) => d.toFixed(1));

    if (_.uniq(densitiesCheck).length > 1) {
      throw new Error(`Incoherent densities for "${key}" [${densitiesCheck.join(' ')}]`);
    }

    return _.mean(densities);
  }

  // static internalDensity(units, key, conversions) {
  //   if (units[key].g === undefined) return undefined;

  //   const mls = [];

  //   if (units[key].ml !== undefined) mls.push(units[key].ml);

  //   Object.keys(units[key]).forEach((target) => {
  //     if (Object.keys(units.ml).includes(target)) {
  //       mls.push(units.ml[target]);
  //     }
  //   });

  //   if (mls.length === 0) return undefined;

  //   const mlsCheck = mls.map((d) => (d / 10).toFixed(0));

  //   if (_.uniq(mlsCheck).length > 1) {
  //     throw new Error(`Incoherent mls for "${key}" [${mlsCheck.join(' ')}]`);
  //   }

  //   return units[key].g / _.mean(mls);
  // }
}

export default Density;
