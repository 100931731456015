<template>
  <div :class="cssClass">
    <div v-if="loadError" class="alert alert-danger" role="alert">
      {{ loadError }}
    </div>
    <div v-if="ready">
      <button
        @click="nextMeasure($event)"
        type="button"
        class="btn btn-sm btn-warning btn-measure" v-if="this.allowedUnits.length > 1">
          <i class="bi bi-arrow-counterclockwise"></i> unidade
        </button>

      <button
        type="button"
        disabled
        class="btn btn-sm btn-secondary btn-measure" v-if="this.allowedUnits.length < 2">
          <i class="bi bi-arrow-counterclockwise"></i> unidade
        </button>

      <div class="ingredients-sum">

        <div v-for="ingredient in addedIngredients" :key="`ai-${itemId}-${ingredient.id}`">
          {{ingredient.amount}} {{ingredient.label}}
        </div>
      </div>
      <div class="tiny-ingredients">
        <slot/>
      </div>
      <div class="ingredient-details">
        <div class="ingredient-name" v-if="itemTitle !== sampleIngredient.name">
          {{ sampleIngredient.name }}
        </div>
        <a class="dish-name link-primary" :href="`#${dishId}`" @click="goTo(dishId, $event)">
          {{ dish.name }}
        </a>
      </div>

    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import * as axios from 'axios';

import Loader from '../alchimia/loader';

import Measures from '../alchimia/measures';
import Presenter from '../alchimia/presenter';

export default {
  name: 'TinyDish',
  props: {
    dishId: String,
    dishPath: Object,
    display: Object,
    done: Boolean,
    highPrecision: Boolean,
    sampleIngredient: Object,
    sharedIngredients: Array,
    allSharedIngredients: Array,
    itemId: Number,
    itemTitle: String,
    units: Object,
  },
  inject: [
    'goTo', 'updateItemUnits', 'updateConsolidatedFor',
    'setDishFor', 'dishLoaded', 'dishLoading', 'storage',
  ],
  data() {
    return {
      loadError: null,
      factors: {},
      dish: null,
      ready: false,
      allowedUnits: [],
      currentIndex: 0,
      addedIngredients: [],
    };
  },
  async created() {
    this.dishLoading(this.itemId);

    await this.loadDish();
  },
  methods: {
    async unitFor(ingredient) {
      const unitKey = `ingredient:${this.dishId}:${ingredient.id}/u`;

      let unit = `${await this.storage.getItem(unitKey)}`;

      if (this.setUnit(unit, true)) return unit;

      if (ingredient['display-unit']) {
        unit = ingredient['display-unit'];
        if (unit.includes('/')) [, unit] = unit.split('/');

        return unit;
      }

      unit = ingredient.unit;
      if (unit.includes('/')) [, unit] = unit.split('/');

      return unit;
    },
    async updateSum() {
      const ingredients = {};

      for (let si = 0; si < this.sharedIngredients.length; si += 1) {
        const ingredient = this.sharedIngredients[si];

        const factoredAmount = ingredient.amount * this.factorFor(ingredient.id);
        const measures = Measures.buildFor(
          this.units, factoredAmount, ingredient.unit,
        );

        let measure;

        for (let m = 0; m < measures.length; m += 1) {
          const measureCandidate = measures[m];

          /* eslint-disable no-await-in-loop */
          if (measureCandidate.unit === await this.unitFor(ingredient)) {
            measure = measureCandidate;
            break;
          }
          /* eslint-enable no-await-in-loop */
        }

        if (!measure) {
          const ingredientError = this.allSharedIngredients[0].name;
          const unitsError = _.uniq(this.allSharedIngredients.map((i) => i.unit)).join(' vs ');
          throw new Error(`Incoherent units for "${ingredientError}" [${unitsError}]`);
        }

        if (!ingredients[measure.unit]) {
          ingredients[measure.unit] = {
            count: 0,
            amount: 0,
            unit: measure.unit,
          };
        }

        ingredients[measure.unit].amount += measure.amount;
        ingredients[measure.unit].count += 1;
      }

      this.addedIngredients = Object.values(ingredients).map((i) => ({
        ...{ rawAmount: i.amount },
        ...Presenter.measure(
          this.display, i.amount, i.unit,
          this.highPrecision, 'up',
        ),
      }));

      if (this.addedIngredients.length === 1) {
        this.setUnit(this.addedIngredients[0].unit);
      } else if (this.addedIngredients.length > 1) {
        const ingredient = _.last(_.sortBy(Object.values(ingredients), ['count']));
        this.setPreviousTo(ingredient.unit);
      }

      this.updateConsolidatedFor(this.itemId);
    },
    factorFor(ingredientId) {
      if (this.factors[ingredientId] !== undefined) {
        return this.factors[ingredientId];
      }
      return this.factors.default;
    },
    async loadFactor() {
      for (let i = 0; i < this.sharedIngredients.length; i += 1) {
        const ingredient = this.sharedIngredients[i];

        /* eslint-disable no-await-in-loop */
        const ingredientFactor = await this.storage.getItem(
          `dish:${this.dishId}:${ingredient.id}/fx`,
        );
        /* eslint-enable no-await-in-loop */

        if (ingredientFactor !== null) {
          this.factors[ingredient.id] = parseFloat(ingredientFactor);
        }
      }

      const dishFactor = await this.storage.getItem(`dish:${this.dishId}/fx`);

      if (dishFactor !== null) {
        this.factors.default = parseFloat(dishFactor);
      } else {
        this.factors.default = 1.0;
      }
    },
    setPreviousTo(target) {
      let unit = target;

      if (target.includes('/')) [, unit] = target.split('/');

      const index = _.findIndex(this.allowedUnits, ((au) => au === unit));

      if (index >= 0) {
        if (index > 1) {
          this.currentIndex = index - 1;
        } else {
          this.currentIndex = this.allowedUnits.length - 1;
        }
      }
    },
    setUnit(target, dontSetIndex) {
      if (!target) return false;

      let unit = target;

      if (target.includes('/')) [, unit] = target.split('/');

      const index = _.findIndex(this.allowedUnits, ((au) => au === unit));

      if (index >= 0) {
        if (!dontSetIndex) this.currentIndex = index;
        return true;
      }
      return false;
    },
    async loadUnit() {
      if (this.setUnit(`${await this.storage.getItem(this.sampleUnitKey)}`)) return;

      if (this.sampleIngredient['display-unit']) {
        this.setUnit(this.sampleIngredient['display-unit']);
      } else {
        this.setUnit(this.sampleIngredient.unit);
      }
    },
    buildAllowedUnits() {
      this.allSharedIngredients.forEach((ingredient) => {
        this.allowedUnits = [...this.allowedUnits, ...Presenter.removeUnhelpful(
          Measures.buildFor(
            this.units, ingredient.amount, ingredient.unit,
          ),
        ).map((m) => m.unit)];
      });

      this.allowedUnits = _.uniq(this.allowedUnits);
    },
    async nextMeasure(event) {
      event.stopPropagation();

      if (this.currentIndex < this.allowedUnits.length - 1) {
        this.currentIndex += 1;
      } else {
        this.currentIndex = 0;
      }

      await this.saveUnit();
      await this.updateSum();
    },
    async saveUnit() {
      for (let i = 0; i < this.sharedIngredients.length; i += 1) {
        const ingredient = this.sharedIngredients[i];

        const unitKey = `ingredient:${this.dishId}:${ingredient.id}/u`;

        /* eslint-disable no-await-in-loop */
        await this.storage.setItem(unitKey, this.currentUnit);
        /* eslint-enable no-await-in-loop */
      }

      await this.updateItemUnits(this.itemId);
    },
    async loadDish() {
      const vueThis = this;
      await axios.get(`built/dishes/${this.dishPath[this.dishId]}.yml`)
        .then(async (response) => {
          try {
            vueThis.dish = Loader.parseYaml(response.data);

            this.loadError = null;
            this.ready = true;

            this.buildAllowedUnits();
            await this.loadUnit();
            await this.loadFactor();
            await this.updateSum();
            this.setDishFor(this.itemId, vueThis.dish);
            await this.dishLoaded(this.itemId);
          } catch (error) {
            this.loadError = error;
            this.ready = false;
          }
        })
        .catch((error) => {
          this.loadError = error;
          this.ready = false;
        });
    },
  },
  computed: {
    currentUnit() {
      return this.allowedUnits[this.currentIndex];
    },
    cssClass() {
      if (!this.done) return 'tiny-dish';

      return 'tiny-dish done';
    },
    sampleUnitKey() {
      return `ingredient:${this.dishId}:${this.sampleIngredient.id}/u`;
    },
  },
};
</script>
