import _ from 'lodash';

import Units from './units';

const FRACTIONS = {
  0.25: '¼',
  0.333333333333: '⅓',
  0.50: '½',
  0.666666666667: '⅔',
  0.75: '¾',
};

const PRECISION_FRACTIONS = {
  0.20: '⅕',
  0.40: '⅖',
  0.60: '⅗',
  0.80: '⅘',
};

const ULTRA_PRECISION_FRACTIONS = {
  0.1: '⅒',
  0.111111111111: '⅑',
  0.125: '⅛',
  0.142857142857: '⅐',
  0.166666666667: '⅙',
  0.375: '⅜',
  0.625: '⅝',
  0.833333333333: '⅚',
  0.875: '⅞',
};

class Presenter {
  static removeUnhelpful(measures) {
    return measures.filter((measure) => {
      if (measure.amount >= 0.05 && Units.NO_FRACTIONS.includes(measure.unit)) {
        return true;
      }

      return measure.amount <= 14.0 && measure.amount >= 0.05;
    });
  }

  static fixed(value, length, directionConfig) {
    let direction = directionConfig;

    if (direction === undefined) direction = 'default';

    const factor = parseInt(`1${'0'.repeat(length)}`, 10);

    if (direction === 'up') {
      const newValue = Math.ceil(value * factor) / factor;
      return newValue.toFixed(length);
    } if (direction === 'down') {
      const newValue = Math.floor(value * factor) / factor;
      return newValue.toFixed(length);
    }
    return value.toFixed(length);
  }

  static measure(display, amount, baseUnit, highPrecision, directionConfig) {
    let direction = directionConfig;

    if (direction === undefined) direction = 'default';

    let unit = baseUnit;

    if (display[unit] === undefined) {
      [, unit] = baseUnit.split('/');
    }

    if (display[unit] === undefined) {
      throw new Error(`Missing display for "${baseUnit}"`);
    }

    let displayAmount;

    if (Units.NO_FRACTIONS.includes(unit)) {
      if (Units.LARGE.includes(unit)) {
        displayAmount = this.readableNumber(parseFloat(this.fixed(amount, 1, direction)));
      } else {
        displayAmount = this.readableNumber(parseFloat(this.fixed(amount, 2, direction)));
      }
    } else {
      displayAmount = this.toFraction(amount, highPrecision);
    }

    return {
      amount: displayAmount,
      unit,
      label: (amount >= 2.0 || displayAmount === '0') ? display[unit].plural : display[unit].singular,
    };
  }

  static readableNumber(value) {
    const thousand = '.';
    const fraction = ',';

    let number = value.toString();
    let remaining = null;

    if (number.includes('.')) {
      [number, remaining] = number.split('.');
    }

    number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousand);

    if (remaining) {
      return `${number}${fraction}${remaining}`;
    }

    // if (number.includes('.')) {
    //   return `${number},00`;
    // }

    return `${number}`;
  }

  static toShort(value, directionConfig) {
    let direction = directionConfig;

    if (direction === undefined) direction = 'default';

    const [left, right] = `${value}`.split('.');

    if (right === undefined) {
      return left;
    }

    let part = this.fixed(parseFloat(`0.${right}`), 2, direction);

    [, part] = `${parseFloat(part)}`.split('.');

    if (part === undefined) {
      return left;
    }

    return `${left},${part}`;
  }

  static toFraction(value, highPrecision, ultraPrecision) {
    const maxThreshold = 0.75;
    const minThreshold = 0.75;
    const integer = parseInt(value, 10);
    const remaining = value - integer;

    const minRemaining = 0.05;
    const maxRemaining = 0.96;

    if (remaining <= minRemaining) {
      return this.readableNumber(integer);
    } if (remaining >= maxRemaining) {
      return this.readableNumber(integer + 1);
    }

    const candidates = [];

    Object.keys(FRACTIONS).forEach((fractionKey) => {
      const fractionValue = parseFloat(fractionKey);

      candidates.push({
        distancePct: Math.abs(((fractionValue / remaining)) - 1.0),
        fractionValue,
        symbol: FRACTIONS[fractionValue],
      });
    });

    if (highPrecision) {
      Object.keys(PRECISION_FRACTIONS).forEach((fractionKey) => {
        const fractionValue = parseFloat(fractionKey);

        candidates.push({
          distancePct: Math.abs((fractionValue / remaining) - 1.0),
          fractionValue,
          symbol: PRECISION_FRACTIONS[fractionValue],
        });
      });
    }

    if (ultraPrecision) {
      Object.keys(ULTRA_PRECISION_FRACTIONS).forEach((fractionKey) => {
        const fractionValue = parseFloat(fractionKey);

        candidates.push({
          distancePct: Math.abs((fractionValue / remaining) - 1.0),
          fractionValue,
          symbol: ULTRA_PRECISION_FRACTIONS[fractionValue],
        });
      });
    }

    const fraction = _.sortBy(candidates, ['distancePct'])[0];

    if (
      !highPrecision && !ultraPrecision
      && (
        fraction.distancePct >= maxThreshold
        || fraction.distancePct <= minThreshold
      )
    ) {
      return this.toFraction(value, true);
    }

    if (!ultraPrecision && (
      fraction.distancePct >= maxThreshold
        || fraction.distancePct <= minThreshold
    )) {
      return this.toFraction(value, true, true);
    }

    if (integer === 0) return fraction.symbol;

    return `${this.readableNumber(integer)} ${fraction.symbol}`;
  }
}

export default Presenter;
