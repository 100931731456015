<template>
  <div class="system-doneness">
    <transition name="fade-fast">
      <div v-if="!system" class="system-loading-container">
        <TinyLoading />
      </div>
    </transition>
    <transition name="fade-fast">
      <div v-if="system">
        <div class="heat">
          <div class="row">
            <div v-if="settings.preheat" class="col col-12 col-md-6">
              {{settings.preheat.description}}
            </div>
            <div v-if="settings.preheat" class="col preheat-time col-12 col-md-6">
              <strong>{{settings.preheat.minutes}}</strong> <span class="unit">min</span>
              até atingir
              <strong>{{system.preheat}}</strong> <span class="unit">{{system.unit}}</span>
            </div>
            <div class="col col-12 col-md-12">
              <div class="cooking" v-if="settings.cooking && settings.preheat">
                {{settings.cooking}}
              </div>
              <div class="cooking cooking-only" v-if="settings.cooking && !settings.preheat">
                {{settings.cooking}}
              </div>
              <div class="cooking no-cooking" v-if="!settings.cooking">
              </div>
            </div>
          </div>
        </div>

        <div class="ranges">
          <div class="range" v-for="range in system.ranges" :key="range.title">
            <div class="item color" :style="`background-color: ${range.color};`"></div>
            <div class="item title">
              {{range.title}}
              <div class="item-time" v-if="minutesFor(range.key) && minutesFor(range.key).at">
                {{minutesFor(range.key).at}} <span class="unit">min</span>
              </div>
              <div class="item-time" v-if="
                    minutesFor(range.key)
                    && !minutesFor(range.key).at
                    && minutesFor(range.key).from">
                {{minutesFor(range.key).from}}
                <span>~</span>
                {{minutesFor(range.key).to}}
                <span class="unit">min</span>
              </div>
            </div>
            <div class="item temperature">
              <div v-if="range.at">
                {{temperatureFor(range.at)}} <span class="unit">{{system.unit}}</span>
              </div>
              <div v-if="range.to && !range.at && average">
                {{averageFor(range)}} <span class="unit">{{system.unit}}</span>
              </div>
              <div v-if="range.to && !range.at && !average">
                {{temperatureFor(range.from)}} <span class="tilde">~</span>
                {{temperatureFor(range.to)}} <span class="unit">{{system.unit}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="settings" v-if="hasRest() || hasAverage()">
          <button
            v-if="hasRest()"
            type="button"
            class="btn btn-sm btn-warning"
            :disabled="done"
            @click="toggleRest($event)">
              <div class="form-check">
                <input
                  @click="toggleRest($event)"
                  class="form-check-input"
                  :disabled="done"
                  :checked="afterRest"
                  type="checkbox">
                  pós-descanço
              </div>
            </button>
            <button
            v-if="hasAverage()"
            type="button"
            class="btn btn-sm btn-warning"
            :disabled="done"
            @click="toggleAverage($event)">
              <div class="form-check">
                <input
                  :disabled="done"
                  @click="toggleAverage($event)"
                  class="form-check-input"
                  :checked="average"
                  type="checkbox">
                  média
              </div>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as axios from 'axios';

import Loader from '../../alchimia/loader';

import Presenter from '../../alchimia/presenter';

import TinyLoading from '../TinyLoading.vue';

export default {
  name: 'SystemDoneness',
  inject: ['pageLoaded'],
  components: { TinyLoading },
  props: {
    done: Boolean,
    path: String,
    settings: Object,
  },
  data() {
    return {
      system: undefined,
      afterRest: false,
      sousVide: false,
      average: true,
    };
  },
  created() {
    this.loadSystem();
  },
  methods: {
    hasRest() {
      return this.system['rest-increase'] !== undefined;
    },
    hasAverage() {
      let hasAverage = false;

      this.system.ranges.forEach((range) => {
        if (range.from !== undefined) hasAverage = true;
      });

      return hasAverage;
    },
    minutesFor(key) {
      const minutes = this.settings.minutes[key];

      if (!minutes) return null;

      if (typeof minutes === 'object') {
        if (minutes.at !== undefined) {
          return { at: Presenter.readableNumber(minutes.at) };
        }
        return {
          from: Presenter.readableNumber(minutes.from),
          to: Presenter.readableNumber(minutes.to),
        };
      }

      return { at: Presenter.readableNumber(minutes) };
    },
    averageFor(range) {
      return this.temperatureFor(
        range.from + ((range.to - range.from) / 2),
      );
    },
    temperatureFor(temperature) {
      if (this.afterRest || this.sousVide || !this.system['rest-increase']) {
        return Presenter.readableNumber(temperature);
      }

      const increase = (
        this.system['rest-increase'].from
        + ((this.system['rest-increase'].to - this.system['rest-increase'].from) / 2)
      );

      return Presenter.readableNumber(temperature - increase);
    },
    toggleAverage(event) {
      if (event) event.stopPropagation();

      if (this.average) {
        this.average = false;
      } else {
        this.average = true;
      }
    },
    toggleRest(event) {
      if (event) event.stopPropagation();

      if (this.afterRest) {
        this.afterRest = false;
      } else {
        this.afterRest = true;
      }
    },
    loadSystem() {
      const vueThis = this;
      axios.get(`built/systems/${this.path}.yml`)
        .then((response) => {
          try {
            vueThis.system = Loader.parseYaml(response.data);

            vueThis.loadError = null;

            vueThis.pageLoaded();
          } catch (error) {
            this.loadError = error;
          }
        })
        .catch((error) => {
          this.loadError = error;
        });
    },
  },
};
</script>
