<template>
  <div class="footer-bar">
    <div class="menu-item dish-progress" v-if="progress !== undefined">
      <Progress :value="progress" />
    </div>

    <div v-if="bookmark" class="menu-item dish-item" @click="this.bookmark.toggler($event)">
      <i v-if="bookmark.state" @click="this.bookmark.toggler($event)" class="bi bi-heart-fill"></i>
      <i v-else @click="this.bookmark.toggler($event)" class="bi bi-heart"></i>

      <span class="menu-title">Hoje</span>
    </div>

    <a href="#index" class="menu-item" @click="goTo('index', $event)">
      <i class="bi bi-journal-richtext"></i>
      <span class="menu-title">Pratos</span>
    </a>
    <a href="#grocery" class="menu-item" @click="goTo('grocery', $event)">
      <i class="bi bi-basket">
      <span
        v-if="groceryState.pending > 0"
        class="grocery-pill badge rounded-pill bg-danger">
        {{groceryState.pending}}
        <span class="visually-hidden">unread messages</span>
      </span>
      </i>

      <span class="menu-title">Mercado</span>
    </a>

    <div
      v-if="microMoment"
      class="menu-item dish-item menu-micro-moment"
      @click="this.microMoment.toggler($event)">
      <i
        @click="this.microMoment.toggler($event)"
        :class="microMomentClass"
        ></i>

      <span class="menu-title">{{microMomentLabel}}</span>
    </div>
  </div>
</template>

<script>
import Progress from './Progress.vue';

export default {
  name: 'Footer',
  inject: ['goTo'],
  components: { Progress },
  props: {
    page: String,
    groceryState: Object,
  },
  data() {
    return {
      microMoment: undefined,
      bookmark: undefined,
      progress: undefined,
    };
  },
  methods: {
    setProgress(progress) {
      this.progress = progress;
    },
    releaseProgress() {
      this.progress = undefined;
    },
    setBookmark(state, toggler) {
      this.bookmark = {
        state,
        toggler,
      };
    },
    setMicroMoment(state, toggler) {
      this.microMoment = {
        state,
        toggler,
      };
    },
    releaseBookmark() {
      this.bookmark = undefined;
    },
    releaseMicroMoment() {
      this.microMoment = undefined;
    },
  },
  computed: {
    microMomentClass() {
      const icon = {
        explore: 'broadcast', // bullseye asterisk octagon
        plan: 'clipboard', // pen book
        stock: 'inboxes',
        miseEnPlace: 'diagram-3', // inboxes-fill
        cook: 'brightness-alt-high',
      }[this.microMoment.state];

      return `bi bi-${icon}`;
    },
    microMomentLabel() {
      return {
        explore: 'Explorando',
        plan: 'Planejando',
        stock: 'Comprar',
        miseEnPlace: 'Mise en Place',
        cook: 'Cozinhando',
      }[this.microMoment.state];
    },
  },
};
</script>
