import * as yaml from 'js-yaml';

import Units from './units';
import Measures from './measures';

class Loader {
  static parseYaml(rawContent) {
    return yaml.load(rawContent);
  }

  static buildUnits(rawUnits) {
    const units = { ...rawUnits };

    Units.STANDARDS.forEach((standardUnit) => {
      if (units[standardUnit]) {
        Object.keys(units[standardUnit]).forEach((key) => {
          if (!units[key]) units[key] = {};

          units[key][standardUnit] = units[standardUnit][key];
        });
      }
    });

    Object.keys(units).forEach((key) => {
      const [ingredient, baseUnit] = key.split('/');

      if (baseUnit !== undefined) {
        const measures = Measures.buildFor(
          rawUnits, 1, key,
        );

        measures.forEach((measure) => {
          if (measure.unit !== baseUnit && Units.STANDARDS.includes(measure.unit)) {
            const newKey = `${ingredient}/${measure.unit}`;
            if (units[newKey] === undefined) units[newKey] = {};

            if (units[newKey][baseUnit] === undefined) {
              units[newKey][baseUnit] = 1.0 / measure.amount;
            }
          }
        });

        // ----------------------

        const subStandardsKeys = [...Object.keys(units.ml), ...Object.keys(units.g)];

        measures.forEach((measure) => {
          if (
            Units.STANDARDS.includes(baseUnit)
            && !Units.STANDARDS.includes(measure.unit)
            && !subStandardsKeys.includes(measure.unit)
          ) {
            const keyToUpdate = `${ingredient}/${baseUnit}`;
            units[keyToUpdate][measure.unit] = 1.0 / measure.amount;
          }
        });
      }
    });

    const subStandardsKeys = [...Object.keys(units.ml), ...Object.keys(units.g)];

    Object.keys(units).forEach((key) => {
      const [ingredient, baseUnit] = key.split('/');

      if (baseUnit !== undefined) {
        const measures = Measures.buildFor(
          rawUnits, 1, key,
        );

        measures.forEach((measure) => {
          if (
            baseUnit !== measure.unit
            && !Units.STANDARDS.includes(baseUnit)
            && !Units.STANDARDS.includes(measure.unit)
            && !subStandardsKeys.includes(measure.unit)
          ) {
            Units.STANDARDS.forEach((standardUnit) => {
              const standardKey = `${ingredient}/${standardUnit}`;
              const baseKey = `${ingredient}/${baseUnit}`;
              const reverseKey = `${ingredient}/${measure.unit}`;

              if (units[standardKey]) {
                units[standardKey][measure.unit] = units[standardKey][baseUnit] * measure.amount;

                if (!units[reverseKey]) units[reverseKey] = {};

                units[reverseKey][standardUnit] = (
                  1 / (units[standardKey][baseUnit] * measure.amount)
                );

                units[reverseKey][baseUnit] = (
                  1 / (units[baseKey][measure.unit])
                );
              }
            });
          }
        });
      }
    });

    Object.keys(units).forEach((key) => {
      const [ingredient] = key.split('/');

      if (units[`${ingredient}/g`] && units[`${ingredient}/ml`]) {
        Object.keys(units[`${ingredient}/g`]).forEach((gUnit) => {
          Object.keys(units[`${ingredient}/ml`]).forEach((mlUnit) => {
            if (gUnit === mlUnit) {
              if (units[`${ingredient}/g`].density === undefined) {
                const density = (
                  units[`${ingredient}/g`][gUnit] / units[`${ingredient}/ml`][gUnit]
                );
                units[`${ingredient}/g`].density = density;
              }
            }
          });
        });
      }
    });

    Object.keys(units).forEach((key) => {
      const [ingredient] = key.split('/');

      if (units[`${ingredient}/g`] && units[`${ingredient}/g`].density) {
        if (!units[`${ingredient}/ml`]) units[`${ingredient}/ml`] = {};

        if (!units[`${ingredient}/ml`].density) {
          units[`${ingredient}/ml`].density = 1 / units[`${ingredient}/g`].density;
        }
      }

      if (units[`${ingredient}/g`] && units[`${ingredient}/g`].ml) {
        if (!units[`${ingredient}/g`].density) units[`${ingredient}/g`].density = units[`${ingredient}/g`].ml;

        if (!units[`${ingredient}/ml`]) units[`${ingredient}/ml`] = {};

        if (!units[`${ingredient}/ml`].density) {
          units[`${ingredient}/ml`].density = 1 / units[`${ingredient}/g`].ml;
        }
      }

      if (units[`${ingredient}/ml`] && units[`${ingredient}/ml`].density) {
        if (!units[`${ingredient}/g`]) units[`${ingredient}/g`] = {};

        if (!units[`${ingredient}/g`].density) {
          units[`${ingredient}/g`].density = 1 / units[`${ingredient}/ml`].density;
        }
      }

      if (units[`${ingredient}/ml`] && units[`${ingredient}/ml`].g) {
        if (!units[`${ingredient}/g`]) units[`${ingredient}/g`] = {};

        if (!units[`${ingredient}/g`].density) {
          units[`${ingredient}/g`].density = 1 / units[`${ingredient}/ml`].g;
        }
      }
    });

    // ----------------------------------------------------

    Object.keys(units).forEach((key) => {
      const [ingredient] = key.split('/');

      if (
        units[`${ingredient}/g`] && units[`${ingredient}/g`].density
        && units[`${ingredient}/ml`] && units[`${ingredient}/ml`].density
      ) {
        [
          ...Object.keys(units[`${ingredient}/g`]),
          ...Object.keys(units[`${ingredient}/ml`]),
        ].forEach((candidate) => {
          if (
            !Units.NO_FRACTIONS.includes(candidate)
            && !subStandardsKeys.includes(candidate)
            && candidate !== 'density'
          ) {
            if (
              units[`${ingredient}/g`][candidate]
              && !units[`${ingredient}/ml`][candidate]
            ) {
              units[`${ingredient}/ml`][candidate] = units[`${ingredient}/g`][candidate] * units[`${ingredient}/ml`].density;
            }

            if (
              units[`${ingredient}/ml`][candidate]
              && !units[`${ingredient}/g`][candidate]
            ) {
              units[`${ingredient}/g`][candidate] = units[`${ingredient}/ml`][candidate] * units[`${ingredient}/g`].density;
            }
          }
        });
      }
    });

    Object.keys(units).forEach((key) => {
      const [ingredient] = key.split('/');

      if (
        units[`${ingredient}/g`] && units[`${ingredient}/g`].density
        && units[`${ingredient}/ml`] && units[`${ingredient}/ml`].density
      ) {
        [
          ...Object.keys(units[`${ingredient}/g`]),
          ...Object.keys(units[`${ingredient}/ml`]),
        ].forEach((candidate) => {
          if (
            !Units.NO_FRACTIONS.includes(candidate)
            && !subStandardsKeys.includes(candidate)
            && candidate !== 'density'
          ) {
            if (!units[`${ingredient}/${candidate}`]) {
              units[`${ingredient}/${candidate}`] = {};
            }

            if (!units[`${ingredient}/${candidate}`].ml && units[`${ingredient}/ml`][candidate]) {
              units[`${ingredient}/${candidate}`].ml = 1 / units[`${ingredient}/ml`][candidate];
            }

            if (!units[`${ingredient}/${candidate}`].g && units[`${ingredient}/g`][candidate]) {
              units[`${ingredient}/${candidate}`].g = 1 / units[`${ingredient}/g`][candidate];
            }
          }
        });
      }
    });

    const gKeys = ['g', ...Object.keys(units.g)];
    const mlKeys = ['ml', ...Object.keys(units.ml)];

    Object.keys(units).forEach((key) => {
      const [ingredient] = key.split('/');

      let keys = [];

      gKeys.forEach((gCandidateKey) => {
        if (units[`${ingredient}/${gCandidateKey}`]) {
          mlKeys.forEach((mlCandidateKey) => {
            if (units[`${ingredient}/${gCandidateKey}`][mlCandidateKey]) {
              keys = [gCandidateKey, mlCandidateKey];
            }
          });
        }
      });

      mlKeys.forEach((mlCandidateKey) => {
        if (units[`${ingredient}/${mlCandidateKey}`]) {
          gKeys.forEach((gCandidateKey) => {
            if (units[`${ingredient}/${mlCandidateKey}`][gCandidateKey]) {
              keys = [mlCandidateKey, gCandidateKey];
            }
          });
        }
      });

      if (keys.length === 2) {
        const [from, to] = keys;

        let mls = null;
        let gs = null;

        if (mlKeys.includes(from)) {
          if (from === 'ml') {
            mls = 1;
          } else {
            mls = units.ml[from];
          }

          if (to === 'g') {
            gs = units[`${ingredient}/${from}`][to];
          } else {
            gs = units[`${ingredient}/${from}`][to] * units.g[to];
          }
        } else if (gKeys.includes(from)) {
          if (from === 'g') {
            gs = 1;
          } else {
            gs = units.g[from];
          }

          if (to === 'ml') {
            mls = units[`${ingredient}/${from}`][to];
          } else {
            mls = units[`${ingredient}/${from}`][to] * units.ml[to];
          }
        }

        if (!units[`${ingredient}/ml`]) units[`${ingredient}/ml`] = {};
        if (!units[`${ingredient}/g`]) units[`${ingredient}/g`] = {};

        units[`${ingredient}/ml`].g = gs / mls;
        units[`${ingredient}/g`].ml = mls / gs;
      }
    });

    return units;
  }
}

export default Loader;
