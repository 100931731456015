import { initializeApp } from 'firebase/app';

import { GoogleAuthProvider, getAuth } from 'firebase/auth';
import credentials from './credentials';

const firebase = {
  app: initializeApp(credentials.firebase),
};

firebase.googleProvider = new GoogleAuthProvider();
firebase.auth = getAuth();

firebase.auth.useDeviceLanguage();

export default firebase;
