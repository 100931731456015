<template>
  <div class="ingredient-component">
    <div class="ingredient">
      <div :class="stepIngredientClass(ingredient.id)">
        <div v-if="grocery" class="in-todo">
          <i v-if="grocery === 'p'" class="pending bi bi-basket-fill"></i>
          <i v-if="grocery === 'd'" class="done bi bi-basket"></i>
        </div>
        <div class="ingredient-name" @click="toggleIngredientCheck(ingredient.id, $event)">
          <div class="check">
            <div class="form-check">
              <input
                @click="toggleIngredientCheck(ingredient.id, $event)"
                :checked="ingredientChecked(ingredient.id)"
                class="form-check-input"
                type="checkbox">
            </div>
          </div>
          <div class="name">
            {{ ingredient.name }}
          </div>
        </div>
        <div class="amount" @click="toggleIngredientCheck(ingredient.id, $event)">
          <div class="amount-value">
            <i v-if="locked && showLockIcon" class="bi bi-link locked"></i>
            {{ presentedMeasure.amount }} {{ presentedMeasure.label }}
          </div>
        </div>
        <div class="open-tools">

          <!-- Grocery -->
           <button
            v-if="microMoment === 'stock' && !grocery"
            type="button"
            @click="toggleGrocery()"
            class="btn btn-sm btn-success">
              <i class="bi bi-basket"></i>
            </button>

           <button
              v-if="microMoment === 'stock' && grocery"
              type="button"
              @click="toggleGrocery()"
              class="btn btn-sm btn-outline-danger">
                <i class="bi bi-x-circle"></i>
              </button>

          <!-- Measure -->

          <button
            @click="nextMeasure($event)"
            type="button"
            :id="`ge-${ingredient.id}`"
            class="btn btn-sm btn-warning btn-measure"
            v-if="microMoment === 'miseEnPlace' && measures.length > 1">
              <i class="bi bi-arrow-counterclockwise"></i>
            </button>

          <button
            type="button"
            disabled
            class="btn btn-sm btn-secondary"
            v-if="microMoment === 'miseEnPlace' && measures.length < 2">
              <i class="bi bi-arrow-counterclockwise"></i>
            </button>

          <!-- Edit -->
          <button
            v-if="showEdit"
            :disabled="!canEdit || ingredientChecked(ingredient.id)"
            @click="toggleToolbar"
            class="btn btn-sm btn-warning">
            <i class="bi bi-pencil"></i>
          </button>
        </div>
      </div>
      <div class="toolbar" v-if="openToolbar">
        <Factor
          ref="factor"
          :factor="factor"
          :baseAmount="currentMeasure.amount"
          :unit="currentMeasure.unit"
          :highPrecision="highPrecision"
          />

          <div class="actions">
            <button
              v-if="!grocery"
              type="button"
              @click="toggleGrocery()"
              class="btn btn-sm btn-success">
                <i class="bi bi-basket"></i> mercado
              </button>
            <button
              v-else
              type="button"
              @click="toggleGrocery()"
              class="btn btn-sm btn-outline-danger">
                <i class="bi bi-x-circle"></i> mercado
              </button>

            <button
              @click="nextMeasure($event)"
              type="button"
              :id="`ge-${ingredient.id}`"
              class="btn btn-sm btn-warning btn-measure" v-if="measures.length > 1">
                <i class="bi bi-arrow-counterclockwise"></i> unidade
              </button>

            <button
              type="button"
              disabled
              class="btn btn-sm btn-secondary" v-if="measures.length < 2">
                <i class="bi bi-arrow-counterclockwise"></i> unidade
              </button>

            <button
              type="button"
              @click="editAmount"
              class="btn btn-sm btn-primary">
                <i v-if="editingAmount" class="bi bi-pencil-fill"></i>
                <i v-else class="bi bi-pencil"></i>
              </button>

            <div class="edit-amount" v-if="editingAmount">
              <input
                @keyup.enter="updateFactoredMeasure"
                :value="shortAmount"
                :id="`input-${ingredient.id}`"
                class="form-control" />

                <button @click="editAmount" type="button" class="btn btn-sm btn-outline-danger">
                  <i class="bi bi-x-circle"></i>

                </button>
                <button
                  @click="updateFactoredMeasure"
                  type="button"
                  class="btn btn-sm btn-success">
                  <i class="bi bi-check-circle-fill"></i>
                </button>
            </div>

          </div>
          <button v-if="openToolbar" @click="toggleToolbar" class="tools-close">
            <i class="bi bi-x-lg"></i>
          </button>
        </div>
    </div>

    <div v-if="showMiseEnPlace">
      <div
        v-for="(miseEnPlace, miseEnPlaceIndex) in ingredient['mise-en-place']"
        :key="miseEnPlace.id">
          <div
            @click="toggleMiseEnPlaceCheck(miseEnPlace.id, $event)"
            :class="miseEnPlaceClass(miseEnPlace.id)"
            v-if="showMiseEnPlaceFor(miseEnPlace.id)">
          <div class="step-check">
              <div class="form-check">
                <input
                  @click="toggleMiseEnPlaceCheck(miseEnPlace.id, $event)"
                  :checked="miseEnPlaceChecked(miseEnPlace.id)"
                  class="form-check-input" type="checkbox">
              </div>
            </div>
            <div class="letter-container">
              <div class="letter">
                {{alphabet[miseEnPlaceIndex]}}
              </div>
            </div>
            <div class="instruction">{{miseEnPlace.description}}</div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import _ from 'lodash';

import { provide } from 'vue';

import TinyGesture from 'tinygesture';
import ToDoModel from '../models/todo';

import Measures from '../alchimia/measures';
import Presenter from '../alchimia/presenter';
import Factor from './Factor.vue';

export default {
  name: 'Ingredient',
  props: {
    units: Object,
    display: Object,
    ingredient: Object,
    factor: Number,
    highPrecision: Boolean,
    dishId: String,
    dishPath: String,
    locked: Boolean,
    microMoment: String,
    stepIndex: Number,
    recipeIndex: Number,
  },
  components: {
    Factor,
  },
  inject: [
    'updateDishFactor', 'closeAllToolbars',

    'ingredientChecked', 'toggleIngredientCheck',

    'miseEnPlaceChecked', 'toggleMiseEnPlaceCheck',

    'updateGrocery', 'updateIngredientsGrocery',
    'sharedIngredients', 'updateIngredientsUnit',
    'lockStepFactorAs', 'storage',
  ],
  data() {
    return {
      longpressPending: false,
      editingAmount: false,
      openToolbar: false,
      factoredMeasures: [],
      allowedUnits: [],
      measures: [],
      currentIndex: 0,
      currentFactor: undefined,
      debouncedUpdateDishFactor: _.debounce(this.updateDishFactor, 10),
      debouncedLockStepFactorAs: _.debounce(this.lockStepFactorAs, 10),
      grocery: false,
      alphabet: [...'abcdefghijklmnopqrstuvwxyz'],
    };
  },
  async created() {
    window.addEventListener('keydown', this.onKeydown);

    this.currentFactor = this.factor;

    provide('updateFactor', this.updateIngredientFactor);
    provide('inputFocus', this.inputFocus);

    this.buildAllowedUnits();

    this.updateMeasures();

    await this.loadUnit();
    await this.loadGrocery();
  },
  methods: {
    showMiseEnPlaceFor(id) {
      if (this.microMoment !== 'cook') return true;

      if (this.miseEnPlaceChecked(id)) {
        return false;
      }
      return true;
    },
    buildAllowedUnits() {
      this.sharedIngredients(this.ingredient['shared-id']).forEach((ingredient) => {
        this.allowedUnits = [...this.allowedUnits, ...Presenter.removeUnhelpful(
          Measures.buildFor(
            this.units, ingredient.amount, ingredient.unit,
          ),
        ).map((m) => m.unit)];
      });

      this.allowedUnits = _.uniq(this.allowedUnits);
    },
    buildGestures() {
      if (this.measures.length < 2) return;

      const target = document.getElementById(`ge-${this.ingredient.id}`);

      const gesture = new TinyGesture(target);

      gesture.on('longpress', async (event) => {
        this.longpressPending = true;

        await this.nextMeasure(event, true);
      });
    },
    onKeydown(e) {
      if (['Escape'].includes(e.key)) {
        this.editingAmount = false;
      }
    },
    editAmount() {
      if (this.editingAmount) {
        this.editingAmount = false;
      } else {
        this.editingAmount = true;

        setTimeout(() => {
          const elementId = `input-${this.ingredient.id}`;
          document.getElementById(elementId).focus();
        }, 0);
      }
    },
    inputFocus() {
      if (this.editingAmount) {
        const elementId = `input-${this.ingredient.id}`;

        document.getElementById(elementId).focus();
      }
    },
    updateFactoredMeasure() {
      const elementId = `input-${this.ingredient.id}`;

      const inputElement = document.getElementById(elementId);

      this.$refs.factor.updateValue(inputElement.value.replaceAll(',', '.'));

      this.editingAmount = false;
    },
    miseEnPlaceClass(id) {
      if (this.miseEnPlaceChecked(id)) {
        return 'step-instruction mise-en-place item-done';
      }
      return 'step-instruction mise-en-place';
    },
    stepIngredientClass(id) {
      if (this.ingredientChecked(id)) {
        return 'ingredient-container item-done';
      }
      return 'ingredient-container';
    },
    async toggleGrocery() {
      if (this.grocery) {
        await ToDoModel.removeIngredient(this.storage, 'grocery', this.dishId, this.ingredient);

        await this.updateIngredientsGrocery();
        await this.loadGrocery();
      } else {
        await ToDoModel.addIngredient(
          this.storage,
          'grocery', this.dishId, this.dishPath,
          this.sharedIngredients(this.ingredient['shared-id']),
        );

        await this.updateIngredientsGrocery();
        await this.loadGrocery();
      }
    },
    async resetUnit() {
      await this.storage.removeItem(this.unitKey);
      await this.loadUnit();
    },
    async loadGrocery() {
      this.grocery = await this.storage.getItem(this.groceryKey);
      await this.updateGrocery();
    },
    async loadUnit() {
      if (this.setUnit(`${await this.storage.getItem(this.unitKey)}`)) return;

      if (this.ingredient['display-unit']) {
        this.setUnit(this.ingredient['display-unit']);
      } else {
        this.setUnit(this.ingredient.unit);
      }
    },
    async saveUnit(fromLongpress) {
      if (fromLongpress) {
        await this.storage.setItem(this.unitKey, this.currentMeasure.unit);
      } else {
        const ingredients = this.sharedIngredients(this.ingredient['shared-id']);

        for (let i = 0; i < ingredients.length; i += 1) {
          const ingredient = ingredients[i];

          const unitKey = `ingredient:${this.dishId}:${ingredient.id}/u`;
          /* eslint-disable no-await-in-loop */
          await this.storage.setItem(unitKey, this.currentMeasure.unit);
          /* eslint-enable no-await-in-loop */
        }
        await this.updateIngredientsUnit();
      }
    },
    updateMeasures() {
      const factoredAmount = this.ingredient.amount * this.currentFactor;

      this.measures = Measures.buildFor(
        this.units, this.ingredient.amount, this.ingredient.unit,
      ).filter((m) => this.allowedUnits.includes(m.unit));

      this.factoredMeasures = Measures.buildFor(
        this.units, factoredAmount, this.ingredient.unit,
      ).filter((m) => this.allowedUnits.includes(m.unit));
    },
    updateIngredientFactor(newFactor) {
      this.currentFactor = newFactor;
      this.updateMeasures();
      if (this.locked) {
        this.debouncedLockStepFactorAs(
          this.recipeIndex, this.stepIndex, this.currentFactor,
        );
      } else {
        this.debouncedUpdateDishFactor(newFactor);
      }
    },
    closeToolbar() {
      this.openToolbar = false;
      this.editingAmount = false;
    },
    toggleToolbar() {
      if (this.openToolbar) {
        this.openToolbar = false;
      } else {
        this.closeAllToolbars();
        this.openToolbar = true;
        setTimeout(() => { this.buildGestures(); }, 0);
      }
    },
    setUnit(target) {
      let unit = target;

      if (target.includes('/')) [, unit] = target.split('/');

      const index = _.findIndex(this.factoredMeasures, (m) => m.unit === unit);

      if (index >= 0) {
        this.currentIndex = index;
        return true;
      }
      return false;
    },
    async nextMeasure(event, fromLongpress) {
      if (event) event.stopPropagation();

      if (!fromLongpress && this.longpressPending) {
        this.longpressPending = false;
        return;
      }

      if (this.currentIndex < this.factoredMeasures.length - 1) {
        this.currentIndex += 1;
      } else {
        this.currentIndex = 0;
      }

      await this.saveUnit(fromLongpress);
    },
  },
  watch: {
    factor(newFactor) {
      this.currentFactor = newFactor;
      this.updateMeasures();
    },
  },
  computed: {
    showMiseEnPlace() {
      return {
        explore: true,
        plan: false,
        stock: false,
        miseEnPlace: true,
        cook: true,
      }[this.microMoment];
    },
    showEdit() {
      return {
        explore: true,
        plan: true,
        stock: false,
        miseEnPlace: false,
        cook: true,
      }[this.microMoment];
    },
    canEdit() {
      return {
        explore: true,
        plan: true,
        stock: false,
        miseEnPlace: false,
        cook: false,
      }[this.microMoment];
    },
    showLockIcon() {
      return {
        explore: true,
        plan: true,
        stock: true,
        miseEnPlace: true,
        cook: false,
      }[this.microMoment];
    },
    shortAmount() {
      return Presenter.toShort(this.currentFactoredMeasure.amount);
    },
    unitKey() {
      return `ingredient:${this.dishId}:${this.ingredient.id}/u`;
    },
    groceryKey() {
      return `ingredient:${this.dishId}:${this.ingredient['shared-id']}/g`;
    },
    currentFactoredMeasure() {
      return this.factoredMeasures[this.currentIndex];
    },
    currentMeasure() {
      return this.measures[this.currentIndex];
    },
    presentedMeasure() {
      return Presenter.measure(
        this.display,
        this.currentFactoredMeasure.amount,
        this.currentFactoredMeasure.unit,
        this.highPrecision,
      );
    },
  },
};
</script>
