<template>
  <div class="factor">
    <input
      @input="onRangeChange"
      @change="onRangeChange"
      :min="min"
      :max="max"
      :step="step"
      :value="current"
      type="range" class="form-range factor-range" :disabled="locked">
  </div>
</template>

<script>
import Units from '../alchimia/units';

export default {
  name: 'Factor',
  inject: ['updateFactor', 'inputFocus'],
  props: {
    unit: String,
    baseAmount: Number,
    factor: Number,
    highPrecision: Boolean,
    locked: Boolean,
  },
  data() {
    return {
      min: 0.1,
      max: 10.0,
      step: 0.01,
      current: 1.0,
      maximumMultiplier: 6,
    };
  },
  created() {
    this.setRange();
  },
  watch: {
    unit() {
      this.setRange();
    },
  },
  methods: {
    setRange() {
      this.current = this.baseAmount * this.factor;

      let rangeSettings;

      if (!Units.NO_FRACTIONS.includes(this.unit)) {
        if (this.highPrecision) {
          rangeSettings = { min: 0.05, step: 0.05 };
        } else {
          rangeSettings = { min: 0.25, step: 0.25 };
        }
      } else if (this.baseAmount >= 15.0) {
        rangeSettings = { min: 1, step: 1 };
      } else {
        rangeSettings = { min: 0.1, step: 0.1 };
      }

      this.min = rangeSettings.min;
      this.max = this.baseAmount * this.maximumMultiplier;

      this.step = rangeSettings.step;
    },
    onRangeChange(event) {
      this.updateValue(event.target.value);
    },
    updateValue(value) {
      this.current = parseFloat(value);

      const factor = this.current / this.baseAmount;

      this.updateFactor(factor);

      this.inputFocus();
    },
  },
};
</script>
