<template>
  <div class="wrapper">
    <!-- <div id="debug" class="base-box"></div> -->
    <!-- <div v-if="offline" class="offline">offline</div> -->
    <div class="container">
      <Auth :firebase="firebase" :offline="offline" :syncStatus="syncStatus" />
      <!-- <div>
        mercado {{groceryState.total}}
        {{groceryState.pending}} {{groceryState.done}}
      </div> -->

      <div class="content">
        <transition name="fade-fast">
          <Loading v-if="loading && !loadError" />
        </transition>

        <transition name="fade-in">
          <div class="load-error base-box" v-if="loadError">
            {{loadError}}
          </div>
        </transition>
        <div v-if="!loadError">
          <transition name="fade-in">
            <IndexPage
              v-if="isIndex && ready && index"
              :index="index"/>
          </transition>

          <transition name="fade-in">
            <GroceryPage
              :units="units"
              :display="display"
              :high-precision="highPrecision"
              v-if="page === 'grocery' && ready"
              />
          </transition>

          <transition name="fade-in">
            <DishPage
              v-if="page === 'dish' && ready && dishPath"
              :units="units"
              :display="display"
              :high-precision="highPrecision"
              :path="dishPath" />
          </transition>

          <transition name="fade-in">
            <SandboxPage
              v-if="page === 'sandbox' && ready"
              :units="units"
              :display="display"
              :high-precision="highPrecision" />
          </transition>
        </div>
      </div>

      <i class="bi bi-pencil fake-icon-to-load-cache"></i>

      <i @click="toggleFullscreen" class="fullscreen bi bi-arrows-fullscreen"></i>

      <div class="footer-push"></div>
    </div>
    <footer class="nav-footer" v-if="ready">
      <Footer ref="footer" :page="page" :grocery-state="groceryState" />
    </footer>
    <Confirm ref="confirmModal" />
  </div>
</template>

<script>
import { provide } from 'vue';

import * as axios from 'axios';

import IndexPage from './pages/Index.vue';
import GroceryPage from './pages/Grocery.vue';
import DishPage from './pages/Dish.vue';
import SandboxPage from './pages/Sandbox.vue';

import Auth from './components/Auth.vue';
import Footer from './components/Footer.vue';
import Confirm from './components/Confirm.vue';

import Loading from './components/Loading.vue';

import Loader from './alchimia/loader';
import Storage from './storage';

import ToDoModel from './models/todo';

export default {
  name: 'App',
  components: {
    Auth,
    IndexPage,
    GroceryPage,
    DishPage,
    SandboxPage,
    Loading,
    Footer,
    Confirm,
  },
  props: {
    firebase: Object,
  },
  data() {
    return {
      homeAliases: ['index', 'home', 'app', 'web'],
      highPrecision: false,
      storage: undefined,
      syncStatus: 'none',
      page: undefined,
      index: undefined,
      units: undefined,
      display: undefined,
      dishPath: undefined,
      storageReady: false,
      pageReady: false,
      ready: false,
      loading: true,
      loadError: null,
      offline: false,
      groceryState: {
        total: 0,
        pending: 0,
        done: 0,
      },
    };
  },
  async created() {
    this.storage = new Storage(this.updateSyncStatus);

    provide('setStorageReady', this.setStorageReady);
    provide('footer', this.footer);
    provide('storage', this.storage);
    provide('confirmModal', this.confirmModal);

    this.registerOnStorage('app', this.storageUpdated);

    window.addEventListener('online', this.updateNetwork);
    window.addEventListener('offline', this.updateNetwork);
    this.updateNetwork();

    provide('goTo', this.goTo);
    provide('currentPage', this.currentPage);
    provide('updateGrocery', this.updateGrocery);
    provide('registerOnStorage', this.registerOnStorage);
    provide('pageLoaded', this.pageLoaded);

    this.loadResources();
    await this.updateGrocery();

    if (window.location.hash) {
      this.goTo(window.location.hash.substr(1));
    } else {
      this.goTo('home');
    }

    window.addEventListener('hashchange', () => {
      if (window.location.hash) {
        this.goTo(window.location.hash.substr(1));
      } else {
        this.goTo('home');
      }
    });
  },
  methods: {
    updateSyncStatus(newStatus) {
      this.syncStatus = newStatus;
    },
    confirmModal() {
      return this.$refs.confirmModal;
    },
    footer() {
      return this.$refs.footer;
    },
    registerOnStorage(id, callback) {
      this.storage.onEvent(id, callback);
    },
    async storageUpdated() {
      await this.updateGrocery();
    },
    async updateGrocery() {
      await ToDoModel.updateGroceryState(this.storage, this);
    },
    toggleFullscreen() {
      const target = document.documentElement;

      if (!document.fullscreenElement && !document.mozFullScreenElement
        && !document.webkitFullscreenElement && !document.msFullscreenElement) {
        if (target.requestFullscreen) {
          target.requestFullscreen();
        } else if (target.msRequestFullscreen) {
          target.msRequestFullscreen();
        } else if (target.mozRequestFullScreen) {
          target.mozRequestFullScreen();
        } else if (target.webkitRequestFullscreen) {
          target.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },
    setStorageReady(value) {
      this.storageReady = value;
      this.checkReady();
    },
    checkReady() {
      this.ready = this.storageReady && this.pageReady;
      if (this.ready) {
        this.loading = false;
        Promise.resolve(this.updateGrocery());
      }
    },
    pageLoaded() {
      setTimeout(() => { this.loading = false; }, 0);
    },
    loadResources() {
      const resources = [
        ['units', '/built/_units.yml'],
        ['display', '/built/_display.yml'],
        ['index', '/built/_index.yml'],
      ];

      this.loadAndParse(resources[0][0], resources[0][1], () => {
        this.loadAndParse(resources[1][0], resources[1][1], () => {
          this.loadAndParse(resources[2][0], resources[2][1], () => {
            this.pageReady = true;
            this.checkReady();
            if (this.ready) this.loading = false;
          });
        });
      });
    },
    updateNetwork() {
      this.offline = !navigator.onLine;
    },
    currentPage() {
      return this.page;
    },
    goTo(rawTarget, event) {
      if (event) event.stopPropagation();

      if (event && event.ctrlKey) return;

      let target;

      if (rawTarget) [target] = rawTarget.split(':');

      this.loading = true;

      if (this.homeAliases.includes(target)) {
        this.page = target;
        this.dishPath = undefined;
        if (this.ready) this.loading = false;
      } else if (['grocery', 'sandbox'].includes(target)) {
        this.page = target;
        this.dishPath = undefined;
      } else {
        this.page = 'dish';
        this.dishPath = target;
      }

      if (rawTarget) {
        window.location.hash = rawTarget;
      } else {
        window.location.hash = this.page;
      }
    },
    loadAndParse(key, path, callback) {
      if (!key) return;

      const vueThis = this;
      axios.get(path)
        .then((response) => {
          vueThis[key] = Loader.parseYaml(response.data);

          if (key === 'units') vueThis[key] = Loader.buildUnits(vueThis[key]);

          if (callback !== undefined) callback();
        })
        .catch((error) => {
          this.loadError = error;
        });
    },
  },
  computed: {
    isIndex() {
      return this.homeAliases.includes(this.page);
    },
  },
};
</script>
