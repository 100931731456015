<template>
  <div class="auth">
    <div v-if="loading">
      <TinyLoading />
    </div>

    <transition name="fade-fast-enter">
      <div class="user" v-if="!loading && user">
        <i v-if="!offline && syncStatus === 'complete'" class="status yes bi bi-wifi"></i>
        <i v-if="!offline && syncStatus === 'pending'" class="status meh bi bi-wifi-2"></i>
        <i v-if="offline" class="status no bi bi-wifi-off"></i>

        <img
          class="profile"
          :title="user.displayName"
          :alt="user.displayName"
          :src="user.photoURL"
          @click="signOutAsk" />
      </div>
    </transition>

    <transition name="fade-fast-enter">
      <div v-if="!loading && !user">
        <i v-if="offline" class="status no bi bi-wifi-off"></i>
        <i v-if="!offline" class="status yes bi bi-wifi"></i>

        <button @click="signIn" type="button" class="btn btn-sm btn-primary">
          <i class="bi bi-google"></i>
        </button>
      </div>
    </transition>

  </div>
</template>

<script>
import { signInWithRedirect, getRedirectResult } from 'firebase/auth';

import TinyLoading from './TinyLoading.vue';

export default {
  name: 'Auth',
  props: {
    syncStatus: String,
    firebase: Object,
    offline: Boolean,
  },
  inject: ['confirmModal', 'storage', 'setStorageReady', 'currentPage', 'goTo'],
  components: { TinyLoading },
  data() {
    return {
      pendingSignOut: undefined,
      loading: true,
      user: undefined,
    };
  },
  async created() {
    let action;

    if (window.location.hash) {
      [, action] = window.location.hash.split(':');
    }

    this.pendingSignOut = await this.storage.getGlobalLocalItem('pendingSignOut');

    this.user = await this.storage.getUser();

    if (action === 'login') {
      this.goTo(window.location.hash.split(':')[0].substr(1));
      this.getLoginResults();
      setTimeout(() => { this.releaseStorage(); }, 5000);
    } else if (this.pendingSignOut) {
      await this.signOut();
      setTimeout(() => { this.releaseStorage(); }, 5000);
    } else if (this.user) {
      setTimeout(() => { this.releaseStorage(); }, 500);
    } else if (this.offline) {
      setTimeout(() => { this.releaseStorage(); }, 500);
    } else {
      setTimeout(() => { this.releaseStorage(); }, 1500);
    }

    this.firebase.auth.onAuthStateChanged(this.onAuthStateChanged);
  },
  methods: {
    releaseStorage() {
      if (this.loading) {
        this.loading = false;
        this.setStorageReady(true);
      }
    },
    async updateCurrentUser(user) {
      if (user && this.pendingSignOut) {
        await this.signOut();
      } else if (user) {
        this.storage.setUser(user).then((storedUser) => {
          this.user = storedUser;
          this.setStorageReady(true);
        });
      } else if (this.pendingSignOut) {
        await this.signOut();
        this.setStorageReady(true);
      } else {
        this.setStorageReady(true);
      }

      this.releaseStorage();
    },

    async onAuthStateChanged(user) {
      await this.updateCurrentUser(user);
    },
    getLoginResults() {
      getRedirectResult(this.firebase.auth)
        .then((result) => {
          if (result) {
            Promise.resolve(this.updateCurrentUser(result.user));
          } else {
            this.setStorageReady(true);
          }
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          throw error;
        });
    },
    async signOut() {
      this.loading = true;
      this.pendingSignOut = true;
      await this.storage.setGlobalLocalItem('pendingSignOut', 't');

      this.user = await this.storage.removeUser();

      await this.firebase.auth.signOut().then(async () => {
        this.loading = false;
        this.pendingSignOut = false;
        await this.storage.removeGlobalLocalItem('pendingSignOut', true);
      }).catch((error) => {
        this.loading = false;
        throw error;
      });
    },
    async signOutAsk() {
      await this.confirmModal().open(
        'Deseja realmente sair?',
        async () => {
          await this.signOut();
        },
      );
    },
    signIn() {
      this.goTo(`${this.currentPage()}:login`);

      signInWithRedirect(
        this.firebase.auth,
        this.firebase.googleProvider,
      );
    },
  },
};
</script>
