import { createApp } from 'vue';

import App from './App.vue';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './assets/css/layout.scss';

import firebase from './firebase';

if ('serviceWorker' in navigator) {
  const waitUntilActive = (worker, retryCount) => {
    if (worker.active) {
      window.location.reload(true);
    } else {
      setTimeout(() => waitUntilActive(worker, retryCount + 1), 0);
    }
  };

  window.addEventListener('load', async () => {
    await navigator.serviceWorker.register('/offline-worker.js').then((worker) => {
      if (worker.active) {
        createApp(App, { firebase }).mount('#app');
      } else {
        waitUntilActive(worker, 0);
      }
    });
  });
}
