<template>
  <div class="grocery">
    <h1>Mercado</h1>
    <div class="base-box">
      <ToDo
        :units="units"
        :display="display"
        :high-precision="highPrecision"
        id="grocery" />
    </div>
  </div>
</template>

<script>
import { inject, provide } from 'vue';

import ToDo from '../components/ToDo.vue';

export default {
  name: 'Grocery',
  components: {
    ToDo,
  },
  props: {
    units: Object,
    display: Object,
    highPrecision: Boolean,
  },
  data() {
    return {
    };
  },
  created() {
    this.goTo = inject('goTo');
    this.pageLoaded = inject('pageLoaded');
    provide('pageLoaded', this.pageLoaded);
  },
};
</script>
