<template>
  <div class="confirm">
    <div class="modal fade" id="confirmModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            {{message}}
          </div>
          <div class="modal-footer">
            <button
              @click="cancel"
              type="button"
              class="btn btn-secondary">Canclear</button>
            <button
              type="button"
              id="confirmButton"
              @click="confirm"
              class="btn btn-danger">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  name: 'Confirm',
  data() {
    return {
      modal: undefined,
      message: null,
      pendingCallback: null,
    };
  },
  mounted() {
    this.modal = new Modal(document.getElementById('confirmModal'));

    document.getElementById('confirmModal').addEventListener('shown.bs.modal', () => {
      document.getElementById('confirmButton').focus();
    });
  },
  methods: {
    open(message, callback) {
      this.message = message;
      this.pendingCallback = callback;
      this.modal.show();
    },
    cancel() {
      this.modal.hide();

      this.pendingCallback = null;
    },
    confirm() {
      this.modal.hide();

      if (this.pendingCallback !== null) this.pendingCallback();

      this.pendingCallback = null;
    },
  },
};
</script>
